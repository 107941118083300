import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'app.routes.const';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { isSkeletonLoading } from 'shared';
import { dateHelper } from 'shared';
import { NumberFormatCustom } from 'shared';

export function useBillingProposalsBuildTable(billingProposals, t) {
  const history = useHistory();
  const headCells = useMemo(() => {
    return [
      { id: 'installation', numeric: false, disablePadding: false, label: t('properties.installation') },
      { id: 'date', numeric: false, disablePadding: false, label: t('properties.date') },
      { id: 'total', numeric: false, disablePadding: false, label: t('properties.total') },
      { id: 'view', action: true }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!billingProposals) {
      return [];
    }
    return billingProposals.items.map(billingProposal => ({
      installation: billingProposal.installation,
      date: isSkeletonLoading(billingProposal.date)
        ? billingProposal.date
        : dateHelper.toShortDate(billingProposal.date.toString()),
      total: isSkeletonLoading(billingProposal.total)
        ? billingProposal.total
        : billingProposal.total && (
            <NumberFormatCustom displayType={'text'} value={Number(billingProposal.total).toFixed(2)} />
          ),
      view: {
        title: t('actions.view'),
        cb: () => history.push(PATH.BILLING_PROPOSALS.EDIT.replace(':id', billingProposal.id)),
        icon: <VisibilityIcon />
      }
    }));
  }, [history, billingProposals, t]);

  return { headCells, rows };
}
