import { apiHelper } from 'shared';

import { http } from './http';

const controllerName = 'plans';

class Plans {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(plan) {
    return http.post(`/${controllerName}`, plan, {});
  }

  delete(id) {
    return http.delete(`/${controllerName}/${id}`, {});
  }

  update(plan) {
    return http.put(`/${controllerName}/${plan.id}`, { ...plan, forceUpdateDescription: true }, {});
  }
}

export const plans = new Plans();
