import { Drawer, SvgIcon } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PATH } from 'app.routes.const';

import { ReactComponent as HgTagLogoNoText } from './../../assets/images/hgtag-logo-no-text.svg';
import hgtagLogo from './../../assets/images/hgtag-logo.PNG';
import { navigationHelper } from './navigation.helper';
import { useNavigationStyle } from './navigation.styles';
import { NavigationList } from './NavigationList';
import { useNavigationStorage } from './useNavigationStorage';

export function Navigation() {
  const { t } = useTranslation();
  const classes = useNavigationStyle();
  let location = useLocation();
  const { isNavOpenisOpen, toggleNavOpen } = useNavigationStorage();
  const navigation = useMemo(() => navigationHelper.generate(t), [t]);
  const highlightedNavigations = useMemo(
    () => navigationHelper.highlighted(navigation, location.pathname),
    [navigation, location.pathname]
  );
  const history = useHistory();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isNavOpenisOpen,
        [classes.drawerClose]: !isNavOpenisOpen
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isNavOpenisOpen,
          [classes.drawerClose]: !isNavOpenisOpen
        })
      }}
    >
      {isNavOpenisOpen ? (
        <div className={classes.hgtagLogo} onClick={() => history.push(PATH.HOME)}>
          <img src={hgtagLogo} alt="ms-logo" className={classes.msLogo} />
        </div>
      ) : (
        <SvgIcon
          component={HgTagLogoNoText}
          class={classes.hgtagFlowsLogoNoText}
          viewBox="0 0 200 200"
          onClick={() => history.push(PATH.HOME)}
        />
      )}

      <NavigationList navigations={highlightedNavigations} isOpen={isNavOpenisOpen} />
      <div
        className={clsx(classes.footer, {
          [classes.footerOpen]: isNavOpenisOpen,
          [classes.footerClose]: !isNavOpenisOpen
        })}
      >
        <Divider />
        <div className={`${classes.toolbar} ${classes.footerWrapperButton}`}>
          <IconButton onClick={toggleNavOpen}>
            {!isNavOpenisOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
}
