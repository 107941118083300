import { Form, Input } from '@engloba-tech/englobity';
import { FormHelperText, Grid } from '@material-ui/core';
import DateRange from '@material-ui/icons/DateRange';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { PlansSelect } from 'pages/shared';
import { usePlans } from 'pages/shared';
import { isSkeletonLoading } from 'shared';
import { LocaleDatePicker } from 'shared';
import { NumberFormatCustom } from 'shared';

export function FormInstallationPlan({ formRef, onSubmit, installationPlan }) {
  const [inputs, setInputs] = useState({ ...installationPlan } || {});
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);
  const [submitted, setSubmitted] = useState();

  const isEditing = Boolean(installationPlan.id);
  const { plans } = usePlans();

  function handleSubmit() {
    setSubmitted(true);
    if (inputs.planId === null) return;
    onSubmit(inputs, Boolean(installationPlan.id));
    setSubmitted(false);
  }

  useEffect(() => {
    setInputs({ ...installationPlan });
  }, [installationPlan]);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]:
          fieldName === 'startDate' || fieldName === 'finishDate' ? target.value && target.value.format('YYYY-MM-DD') : target.value
      }));
    };

  const handleChangePlan = ({ target }) => {
    const planId = target.value;
    setTimeout(() => {
      setInputs(prevInputs => ({
        ...prevInputs,
        planId,
        plan: planId ? plans.items.find(plan => plan.id === target.value).planType : null,
        baseRate: planId ? plans.items.find(plan => plan.id === target.value).baseRate : '',
        experienceRate: planId ? plans.items.find(plan => plan.id === target.value).experienceRate : '',
      }));

    });
  };

  return (
    <>
      <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PlansSelect
              withSelectOption
              plans={plans}
              value={inputs.planId}
              disabled={isEditing}
              onChange={handleChangePlan}
              required
            />
            {!inputs.planId && submitted && <FormHelperText error>{t('validations.required')}</FormHelperText>}
          </Grid>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.baseRate)}
              onChange={handleChange('baseRate')}
              name={t('installationPlans.properties.baseRate')}
              label={t('installationPlans.properties.baseRate')}
              variant="outlined"
              InputProps={{ inputComponent: NumberFormatCustom }}
              value={inputs.baseRate ? Number(inputs.baseRate).toFixed(2) : ''}
              required
              validators={['required', 'minNumber: 0']}
              errorMessages={[t('validations.required'), t('validations.negativeAmountsNotAccepted')]}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.experienceRate)}
              onChange={handleChange('experienceRate')}
              name={t('installationPlans.properties.experienceRate')}
              label={t('installationPlans.properties.experienceRate')}
              variant="outlined"
              InputProps={{ inputComponent: NumberFormatCustom }}
              value={inputs.experienceRate ? Number(inputs.experienceRate).toFixed(2) : ''}
              required
              validators={['required', 'minNumber: 0']}
              errorMessages={[t('validations.required'), t('validations.negativeAmountsNotAccepted')]}
            />
          </Grid>
          <Grid item xs={6}>
            <LocaleDatePicker
              isLoading={isSkeletonLoading(inputs.startDate)}
              required
              icon={<DateRange />}
              name={t('installationPlans.properties.startDate')}
              label={t('installationPlans.properties.startDate')}
              value={inputs.startDate}
              onChange={handleChange('startDate')}
              inputProps={{ 'aria-label': 'date' }}
              validators={['required']}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
          <Grid item xs={6}>
            <LocaleDatePicker
              isLoading={isSkeletonLoading(inputs.finishDate)}
              icon={<DateRange />}
              name={t('installationPlans.properties.finishDate')}
              label={t('installationPlans.properties.finishDate')}
              value={inputs.finishDate}
              onChange={handleChange('finishDate')}
              inputProps={{ 'aria-label': 'date' }}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormInstallationPlan.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errorInfo: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  installationPlan: PropTypes.object,
  createMode: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  setErrorInfo: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired
};
