import { DialogModal, Form, Input, useHandleOpen } from '@engloba-tech/englobity';
import { Avatar, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading, useFormState } from 'shared';
import { PasswordPolicyTooltip } from 'shared';

import { FormAvatar } from './FormAvatar';
import { useFormUserStyles } from './formUser.styles';

export function FormUser({ formRef, onSubmit, user, createMode, errors, profile, onChangeInput }) {
  const [inputs, setInputs] = useFormState({ ...user } || { roles: [], processRoles: [] }, onChangeInput);
  const { t } = useTranslation(NAME_SPACE.USERS);
  const classes = useFormUserStyles();
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formAvatarRef = useRef(null);

  function handleSubmit() {
    onSubmit({ ...inputs, processRoles: inputs.processRoles && inputs.processRoles.map(p => p.id) });
  }

  useEffect(() => {
    setInputs({ ...user }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleUser =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  function handleModalAccept() {
    $formAvatarRef.current.submit();
  }

  const handelAccept = file => {
    setInputs(prevInputs => ({ ...prevInputs, avatar: file }));
    handleClose();
  };

  return (
    <>
      <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          {!createMode && (
            <Grid item>
              <Avatar
                src={`data:image/jpeg;base64,${inputs.avatar || ''}`}
                onClick={profile ? handleOpen : null}
                className={`${classes.picture} ${profile && classes.pointer}`}
              />
            </Grid>
          )}
          {createMode ? (
            <Grid item xs={12}>
              <Input
                isLoading={isSkeletonLoading(inputs.userName)}
                required
                name={t('properties.userName')}
                label={t('properties.userName')}
                variant="outlined"
                value={inputs.userName || ''}
                disabled={!createMode || profile}
                onChange={handleUser('userName')}
                inputProps={{ 'aria-label': t('properties.userName') }}
                validators={['required', 'isEmail', 'maxStringLength:255']}
                errorMessages={[
                  t('validations.required'),
                  t('validations.invalidEmail'),
                  t('common:validations.maxStringLength', { length: 255 })
                ]}
              />
            </Grid>
          ) : (
            <Grid item className={classes.usernameContainer}>
              {!isSkeletonLoading(inputs.userName) ? (
                <Typography className={classes.username} variant="h6">
                  {inputs.userName || ''}
                </Typography>
              ) : (
                <Skeleton width={100} />
              )}
            </Grid>
          )}
          {createMode && (
            <>
              <Grid item xs={6}>
                <PasswordPolicyTooltip placement="bottom">
                  <Input
                    required
                    type="password"
                    name={'password'}
                    label={t('properties.password')}
                    variant="outlined"
                    margin="normal"
                    value={inputs.password || ''}
                    onChange={handleUser('password')}
                    inputProps={{ 'aria-label': t('properties.password') }}
                    validators={['required']}
                    errorMessages={[t('validations.required')]}
                  />
                </PasswordPolicyTooltip>
              </Grid>
              <Grid item xs={6}>
                <Input
                  required
                  type="password"
                  name={'repeatPassword'}
                  label={t('properties.repeatNewPassword')}
                  variant="outlined"
                  margin="normal"
                  value={inputs.repeatPassword || ''}
                  onChange={handleUser('repeatPassword')}
                  inputProps={{ 'aria-label': t('properties.repeatPassword') }}
                  validators={['required', `isPasswordMatch:${inputs.password}`]}
                  errorMessages={[t('validations.required'), t('validations.passwordMissmatch')]}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Form>

      <DialogModal
        title={t('common:import.dialogTitle')}
        description=""
        fullWidth
        isOpen={isOpen}
        onClose={handleClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleClose
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept
          }
        ]}
      >
        <FormAvatar avatar={inputs.avatar} formRef={$formAvatarRef} onSubmit={handelAccept} />
      </DialogModal>
    </>
  );
}

FormUser.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    alias: PropTypes.string,
    role: PropTypes.string,
    useExternalProvider: PropTypes.bool,
    roles: PropTypes.array,
    processRoles: PropTypes.array
  }),
  createMode: PropTypes.bool.isRequired,
  profile: PropTypes.bool,
  updatedUser: PropTypes.bool,
  onChangeInput: PropTypes.func
};
