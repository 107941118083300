import React, { useState } from 'react';

export const UserStateContext = React.createContext({});

export const useUserStateContext = () => {
  const [notificationsCounter, setNotificationsCounter] = useState(null);
  const [listFilters, _setListFilters] = useState({});

  const setListFilters = (key, filters) => {
    _setListFilters(prevFilters => ({
      ...prevFilters,
      [key]: filters
    }));
  };

  const getListFilters = (key) => {
    return listFilters[key] ?? {};
  };

  const clearUserState = () => {
    _setListFilters({});
    setNotificationsCounter(null);
  };

  return { clearUserState, notificationsCounter, listFilters, getListFilters, setListFilters, setNotificationsCounter };
};
