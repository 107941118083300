import { useState, useEffect } from 'react';
import { plans } from 'services';
import { skeletonFactory } from 'shared';

const plansDummy = skeletonFactory.plans();

export function usePlans() {
  const [plansData, setPlansData] = useState(plansDummy);

  useEffect(() => {
    async function getData() {
      const response = await plans.get();
      if (!response) {
        return;
      }

      setPlansData(response.data || []);
    }
    getData();
  }, []);

  return { plans: plansData, setPlansData };
}
