import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ICONS, PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { FormPage, isSkeletonLoading } from 'shared';

import { FormBillingProposal } from './FormBillingProposal';
import { useBillingProposal } from './useBillingProposal';

export function EditBillingProposal() {
  const { id } = useParams();
  const { billingProposal } = useBillingProposal(id);
  const { t } = useTranslation(NAME_SPACE.BILLING_PROPOSALS);
  const history = useHistory();

  return (
    <FormPage
      title={
        <>
          {ICONS.BILLING_PROPOSALS}&nbsp;&nbsp;
          {`${t('singularName')}`}
          {!isSkeletonLoading(billingProposal.installation) &&
            billingProposal.installation &&
            billingProposal.id &&
            ` | ${billingProposal.installation}`}
        </>
      }
      disabled
      onBack={() => history.push(PATH.BILLING_PROPOSALS.LIST)}
      unstyled
    >
      <FormBillingProposal billingProposal={{ ...billingProposal }} />
    </FormPage>
  );
}
