import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from './../../assets/images/header-2x.png';

export const useHeaderStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    fontSize: '13px',
    height: '35px',
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiSvgIcon-root': {
      width: '0.9em',
      height: '0.9em'
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      marginLeft: '15px',
      fontSize: '12px',
      '& p': {
        margin: '0'
      }
    },
    '& .MuiAvatar-root':{
      width: '23px',
      height: '23px',
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '0.7rem',
      alignItems: 'center',
      flexShrink: '0',
      fontFamily: 'Mulish,Roboto',
      justifyItems: 'center',
      borderRadius: '25px',
      marginRight: '10px',
      justifyContent: 'center',
      backgroundColor: theme.palette.basic.semidark
    }
  },
  topBar: {
    margin: theme.spacing(3),
    marginBottom: 0,
    backgroundImage:  `url(${backgroundImage})`,
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '& .MuiIconButton-label': {
      color: 'white'
    },
    '& p':{
      color: 'white'
    }
  },
}));
