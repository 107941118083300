import { makeStyles } from '@material-ui/core/styles';

const buttonCommonStyles = {
  height: '3rem',
  paddingLeft: '2rem',
  paddingRight: '2rem'
};

export const useButtonStyles = makeStyles(theme => ({
  textButton: {
    margin: 0,
    marginLeft: '0.2rem',
    marginTop: '0.1rem',
    marginRight: '0.2rem'
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonCommonStyles,
  button: {
    ...buttonCommonStyles,
    fontWeight: 'bold',
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: 'white'
		},
  }
}));
