import { useCallback, useEffect, useState } from 'react';

import { installations } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const installationDummy = skeletonFactory.installation();

const defaultInstallation = { daysOff: [] };

export function useInstallation(id) {
  const [installationData, setInstallationData] = useState(id ? installationDummy : { ...defaultInstallation });
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await installations.getById(id);
        if (response) {
          setInstallationData(response.data || {});
        }
      }, false);
    }
    id && getData();
  }, [request, id]);

  const saveInstallation = useCallback(
    async installation => {
      return await request(
        async () => {
          setInstallationData(installationDummy);
          let response;
          if (id) {
            response = await installations.update(installation);
            setInstallationData(response.data);
          } else {
            response = await installations.create(installation);
          }

          return response.data.id;
        },
        true,
        () => setInstallationData(installation)
      );
    },
    [id, request]
  );

  return {
    installation: installationData,
    errorInfo,
    setErrorInfo,
    setInstallationData,
    saveInstallation
  };
}
