import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import React from 'react';

import { PATH } from 'app.routes.const';
import { ICONS } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';

function generate(t) {
  return [
    {
      name: t(`${NAME_SPACE.PLANS}:name`),
      icon: ICONS.PLANS,
      url: PATH.PLANS.LIST,
      prefixUrlName: 'plan'
    },
    {
      name: t(`${NAME_SPACE.INSTALLATIONS}:name`),
      icon: ICONS.INSTALLATIONS,
      url: PATH.INSTALLATIONS.LIST,
      prefixUrlName: 'installation'
    },
    {
      name: t(`${NAME_SPACE.BILLING_PROPOSALS}:name`),
      icon: ICONS.BILLING_PROPOSALS,
      url: PATH.BILLING_PROPOSALS.LIST,
      prefixUrlName: 'billing-proposal'
    },
    {
      name: t(`${NAME_SPACE.USERS}:name`),
      url: PATH.USERS.LIST,
      icon: <GroupOutlinedIcon />,
      prefixUrlName: 'user'
    }
  ];
}

function highlighted(navigations, pathname) {
  return navigations.map(navigation => {
    navigation.highlighted = pathname.includes(navigation.prefixUrlName);
    return navigation;
  });
}

export const navigationHelper = {
  generate,
  highlighted
};
