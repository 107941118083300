import { makeStyles } from '@material-ui/core';

export const usContainerStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    background: theme.palette.secondary.light,
    filter: 'drop-shadow(0px 1px 10px rgba(74, 86, 109, 0.05))'
  },
  pageContainer: {
    padding: theme.spacing(3),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      paddingTop: 0,
      flexGrow: 1,
    }
  }
}));
