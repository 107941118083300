import { makeStyles } from '@material-ui/core/styles';

export const useListUsersStyles = makeStyles(theme => ({
  users: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    margin: '2rem 0 1.5rem 0'
  },
  valignMiddle: {
    display: 'inline-block',
    marginRight: '2rem',
    align: 'center',
    verticalAlign: 'middle',
  }
}));
