import React from 'react';
import { useToolbarStyles } from './toolbar.styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export function Toolbar({ className, ...props }) {
  const classes = useToolbarStyles();
  return <div className={clsx(className, classes.toolbar)}>{props.children}</div>;
}

Toolbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
