import React, { useContext, useEffect, useCallback } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, Input, Button, Summary } from '@engloba-tech/englobity';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestLoading } from 'shared';
import { NAME_SPACE } from 'i18n';
import { UserContext } from 'auth';
import { useHistory } from 'react-router-dom';
import { PATH } from 'app.routes.const';
import { LoadingPage } from 'pages';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  rememberPassword: {
    float: 'right',
    fontWeight: 300,
    fontSize: '0.8rem'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  textCenter: {
    justifyContent: 'center'
  },
  divisor: {
    borderTop: '0.1px solid #e9e9e9',
    borderLeft: '0px',
    marginBottom: '1rem',
    background: 'transparent',
    width: '100%'
  },
  summary: {
    marginBottom: '1rem !important',
    alignItems: 'center'
  },
  imgFluid: {
    height: 'auto',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    marginBottom: '4rem'
  }
}));

export function Login() {
  const classes = useStyles();
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { login, errorInfo, externalLogin, externalLoginError } = useContext(UserContext);
  const { callbackRequest } = useRequestLoading();
  const history = useHistory();

  const enterLogin = useCallback(() => {
    const returnUrl = new URLSearchParams(window.location.search).get('returnUrl');
    history.push(returnUrl || PATH.HOME);
  }, [history]);

  useEffect(() => {
    async function checkExternalLogin() {
      const external = await externalLogin();
      if (external) enterLogin();

      setIsLoading(false);
    }

    checkExternalLogin();
  }, [externalLogin, enterLogin]);

  async function handleLogin() {
    await callbackRequest(async () => {
      await login(inputs);
      enterLogin();
    });
  }

  const handleField =
    fieldName =>
    ({ target }) =>
      setInputs(prevInputs => ({ ...prevInputs, [fieldName]: target.value }));

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
    <p style={{ fontSize: '35px', color: 'black', fontFamily: 'Poppins',fontWeight: 'bold' }}>Packaging Client Manager 🖥️</p>
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        {externalLoginError ? (
          <Summary className={classes.summary} text={t(externalLoginError.message)} severity="error" />
        ) : (
          ''
        )}

        <Form onSubmit={handleLogin} errors={errorInfo} className={classes.form} noValidate>
          <Input
            onChange={handleField('user')}
            value={inputs.user}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder={`${t('email')}*`}
            name="email"
            autoComplete="email"
            autoFocus
            inputProps={{ 'aria-label': 'user' }}
            validators={['required', 'isEmail']}
            errorMessages={[t('validations.required'), t('validations.invalidEmail')]}
          />
          <Input
            onChange={handleField('password')}
            value={inputs.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            placeholder={`${t('password')}*`}
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            validators={['required']}
            errorMessages={[t('validations.required')]}
          />
          <Button isSubmit fullWidth color="primary" className={classes.submit}>
            {t('login.signIn')}
          </Button>
        </Form>
      </div>
    </Container>
    </>
  );
}
