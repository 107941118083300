import React from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { plans as plansService } from 'services';
import { EditModal, PageHeader, useCreateOrUpdate, useSelectedElement, ViewTable } from 'shared';
import { useViewTableData } from 'shared';
import { skeletonFactory } from 'shared';

import { FormPlan } from './FormPlan';
import { usePlansBuildTable } from './usePlansBuildTable';

const plansDummy = skeletonFactory.plans();

export function ListPlans() {
  const { t } = useTranslation(NAME_SPACE.PLANS);

  const {
    data: plans,
    get: getPlans,
    currentRequestParams
  } = useViewTableData({
    service: plansService,
    paginatedSkeleton: plansDummy
  });
  const { selectedElement: selectedPlan, handleSelect, handleUnselect } = useSelectedElement();
  const { createOrUpdate } = useCreateOrUpdate({ service: plansService });
  const { rows, headCells } = usePlansBuildTable(plans, t, handleSelect);

  const handleCloseEditModal = () => {
    handleUnselect();
    getPlans(currentRequestParams);
  };

  return (
    <>
      <PageHeader>{t('name')}</PageHeader>

      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'planType', direction: 'asc' }}
        onFetchData={getPlans}
        totalRows={plans?.total}
        allowRowSelection={false}
        serverSidePaging
      />

      <EditModal
        entity={selectedPlan}
        handleCloseEditModal={handleCloseEditModal}
        onSubmitEditModal={createOrUpdate}
      >
        <FormPlan plan={selectedPlan} />
      </EditModal>
    </>
  );
}
