import { ButtonDelete } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'auth';
import { execConfirmModal } from 'shared';
import { useButtonStyles } from '../Button/button.styles';

export function ClientManagementButtonDelete({
  elementsToDelete,
  tooltip,
  text,
  onConfirm,
  confirmDescription,
  requiredAccess,
  ...props
}) {
  const { t } = useTranslation();
  const disabled = !elementsToDelete?.length;
  const { hasFullAccess } = useContext(UserContext);
  const classes = useButtonStyles();

  const renderButton = () => {
    return (
      hasFullAccess() && (
        <ButtonDelete
          disabled={disabled}
          text={text || t('actions.remove')}
          className={classes.buttonCommonStyles}
          tooltip={{
            title: (disabled && (tooltip?.disabled || t('actions.disabled.select'))) || '',
            placement: 'top'
          }}
          onClick={async () =>
            await execConfirmModal({
              title: t('common:actions.remove'),
              description: confirmDescription(),
              confirmText: t('common:actions.accept'),
              declineText: t('common:actions.cancel'),
              onConfirm: async () => await onConfirm(elementsToDelete)
            })
          }
        />
      )
    );
  };

  return requiredAccess ? hasFullAccess(requiredAccess) && renderButton() : hasFullAccess() && renderButton();
}

ClientManagementButtonDelete.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string
  }),
  requiredProps: PropTypes.array,
  successMessage: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  handleCloseEditModal: PropTypes.func.isRequired,
  handleAcceptEditModal: PropTypes.func.isRequired,
  onSubmitEditModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorInfo: PropTypes.object
};
