import React from 'react';
import PropTypes from 'prop-types';
import { useFormContainerStyles } from './formContainer.styles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

export function FormContainer({ grow = true, ...props }) {
  const classes = useFormContainerStyles();

  return (
    <Grid container spacing={3} className={clsx(classes.formContainer, 'formContainer', grow ? '' : 'grow-0')}>
      {props.children}
    </Grid>
  );
}

FormContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  grow: PropTypes.bool
};
