import { useCallback } from 'react';
import { useHandleRequest } from 'shared/useHandleRequest';

export function useCreateOrUpdate({ service }) {
  const { request } = useHandleRequest();

  const createOrUpdate = useCallback(
    async (element, parentId) => {
      return await request(async () => {
        return !element.id ? await service.create(element, parentId) : await service.update(element, parentId);
      }, true);
    },
    [request, service]
  );

  return { createOrUpdate };
}
