import moment from 'moment';

function validateBeforeExecute(string, cb) {
  if (typeof string === 'string') {
    return cb();
  } else {
    throw new Error(`string required, ${typeof string} found`);
  }
}

function toShortDate(string) {
  return validateBeforeExecute(string, () => moment(string).format('DD/MM/YYYY'));
}

function toLongDate(string) {
  return validateBeforeExecute(string, () => moment(string).format('DD/MM/YY HH:mm'));
}

function convertToDate(dateString) {
  //  Convert a "dd/MM/yyyy" string into a Date object
  let d = dateString.split('/');
  let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  return dat;
}

// TODO toLongDate, toHour

export const dateHelper = {
  toShortDate,
  toLongDate,
  convertToDate
};
