import React from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { installations as installationsService } from 'services';
import { PageHeader, ViewTable } from 'shared';
import { useViewTableData } from 'shared';
import { skeletonFactory } from 'shared';

import { useInstallationsBuildTable } from './useInstallationsBuildTable';

const installationsDummy = skeletonFactory.installations();

export function ListInstallations() {
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);

  const {
    data: installations,
    get: getInstallations,
  } = useViewTableData({
    service: installationsService,
    paginatedSkeleton: installationsDummy
  });

  const { rows, headCells } = useInstallationsBuildTable(installations, t);

  return (
    <>
      <PageHeader>{t('name')}</PageHeader>

      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'name', direction: 'asc' }}
        onFetchData={getInstallations}
        totalRows={installations?.total}
        allowRowSelection={false}
        serverSidePaging
      />
    </>
  );
}
