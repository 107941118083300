import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@engloba-tech/englobity';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';

export function PlansSelect({ plans, value, withSelectOption, ...props }) {
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);

  const elements = useMemo(() => {
    return [
      plans && withSelectOption && { value: null, name: t('common:options.choose') },
      ...plans?.items.map(plan => {
        return {
          value: plan.id,
          name: plan.planType
        };
      })
    ];
  }, [plans, t, withSelectOption]);

  return (
    <Select
      isLoading={isSkeletonLoading(plans[0]) || isSkeletonLoading(value)}
      {...props}
      value={value || null}
      displayEmpty
      title={t('installationPlans.properties.plan')}
      elements={elements}
    />
  );
}

PlansSelect.propTypes = {
  value: PropTypes.number,
  withSelectOption: PropTypes.bool,
  plans: PropTypes.array
};
