import { useState } from 'react';

export function useCheckedElements({ t, mainKey, secondaryKey, prefix, action }) {
  const [checkedElements, setCheckedElements] = useState([]);

  const handleCheckedElements = checkedElements => {
    action(checkedElements);
    setCheckedElements([]);
  };

  const onCheckElement = element => {
    if (!checkedElements.find(elem => elem.id === element.id)) {
      setCheckedElements([...checkedElements, element]);
    } else {
      setCheckedElements(checkedElements.filter(elem => elem.id !== element.id));
    }
  };

  const onCheckAllElements = elements => {
    setCheckedElements(checkedElements.length ? [] : elements);
  };

  const getDeleteDescription = () => {
    const message = prefix ? `${prefix}.removeConfirm.message` : 'removeConfirm.message';
    const singularMessage = prefix ? `${prefix}.removeConfirm.singularMessage` : 'removeConfirm.singularMessage';
    if (checkedElements && checkedElements.length) {
      return checkedElements.length > 1
        ? t(message, { length: checkedElements.length })
        : t(singularMessage, {
            [mainKey]: checkedElements[0][mainKey],
            [secondaryKey]: checkedElements[0][secondaryKey]
          });
    }
  };

  const isAnyChecked = () => {
    return checkedElements?.length;
  };

  return {
    checkedElements,
    getDeleteDescription,
    onCheckElement,
    onCheckAllElements,
    isAnyChecked,
    handleCheckedElements
  };
}
