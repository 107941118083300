import { makeStyles } from '@material-ui/core/styles';

export const useFormUserStyles = makeStyles(theme => ({
  username: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  usernameContainer: {
    alignSelf: 'center'
  },
  picture: {
    width: '8rem',
    height: 'auto',
    fontSize: theme.font.size.xxl,
    '&.MuiAvatar-root': {
      height: '8rem'
    }
  },
  headerUser: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  pointer: {
    cursor: 'pointer'
  },
  marginLeft: {
    marginLeft: '1rem'
  },
  flexDirectionColumn: {
    flexDirection: 'column'
  },
  end: {
    alignItems: 'end'
  },
  icon: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    alignSelf: 'center'
  },
  hideIcon: {
    display: 'none'
  },
  hover: {
    '&:hover': {
      '& .MuiSvgIcon-root': {
        display: 'flex !important'
      }
    }
  },
  inputsContainer: {
    margin: '0 0 0.6rem 0'
  },
  paddingTop: {
    marginTop: theme.spacing(2)
  }
}));
