import React from 'react';
import { usePageHeaderStyles } from './pageHeader.styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

export function PageHeader({ className, ...props }) {
  const classes = usePageHeaderStyles();
  return (
    <Typography className={clsx(classes.pageHeader, 'pageHeader', className)} variant="h6" id="tableTitle" component="div">
      {props.children}
    </Typography>
  );
}

PageHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
