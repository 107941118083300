import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import globalStyles from 'styles';

// https://material-ui.com/es/components/snackbars/

export const TOASTER_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success'
};
export function SnackAlert({ name, text, type, unmount, position, duration = 200000 }) {
  return (
    <Snackbar anchorOrigin={position} open={true} autoHideDuration={duration} onClose={unmount}>
      <Alert onClose={unmount} severity={type}>
        <AlertTitle id="alert_title">{`${name}`}</AlertTitle>
        {text}
      </Alert>
    </Snackbar>
  );
}

export function execToaster({ name, text, type, position = { vertical: 'bottom', horizontal: 'right' } }) {
  const idGenerated = shortid.generate();
  const entryPointToasterDom = document.createElement('div');
  entryPointToasterDom.setAttribute('id', idGenerated);

  const global = document.getElementById('global');
  global.appendChild(entryPointToasterDom);

  ReactDOM.render(
    <ThemeProvider theme={globalStyles}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <SnackAlert
        name={name}
        // text={text}
        type={type}
        position={position}
        unmount={() => {
          ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
          global.removeChild(entryPointToasterDom);
        }}
      />
    </ThemeProvider>,
    document.getElementById(idGenerated)
  );
}

export function successToaster(text) {
  execToaster({
    name: text,
    type: TOASTER_TYPES.SUCCESS
  });
}

export function warningToaster(text) {
  execToaster({
    name: text,
    type: TOASTER_TYPES.WARNING
  });
}

export function errorToaster(text) {
  execToaster({
    name: text,
    type: TOASTER_TYPES.ERROR
  });
}
export function infoToaster(text) {
  execToaster({
    name: text,
    type: TOASTER_TYPES.INFO
  });
}

SnackAlert.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  position: PropTypes.object,
  unmount: PropTypes.func,
  duration: PropTypes.number
};
