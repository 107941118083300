import { makeStyles } from '@material-ui/core/styles';

export const useEditModalStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '50rem'
    }
  },
  successMessage: {
    marginBottom: theme.spacing(3)
  }
}));
