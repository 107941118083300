import { useState } from 'react';

export function useSelectedElement() {
  const [selectedElement, setSelectedElement] = useState(null);

  const handleSelect = element => {
    setSelectedElement(element);
  };

  const handleNew = () => {
    setSelectedElement({});
  };

  const handleUnselect = () => {
    setSelectedElement(null);
  };

  return { selectedElement, handleSelect, handleNew, handleUnselect };
}
