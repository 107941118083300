import { ICONS, PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FormPage, isSkeletonLoading, useRequestLoading } from 'shared';
import { FormInstallation } from './FormInstallation';
import { useInstallation } from './useInstallation';

export function EditInstallation() {
  const { id } = useParams();
  const { installation, saveInstallation } = useInstallation(id);
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);
  const history = useHistory();
  const { callbackRequest } = useRequestLoading();

  async function handleSubmit(installation) {
    try {
      await callbackRequest(async () => {
        const newId = await saveInstallation(installation);
        history.push(PATH.INSTALLATIONS.EDIT.replace(':id', newId));
      });
    } catch (error) {}
  }

  return (
    <FormPage
      title={
        <>
          {ICONS.INSTALLATIONS}&nbsp;&nbsp;
          {`${t('singularName')}`}
          {!isSkeletonLoading(installation.name) && installation.name && installation.id && ` | ${installation.name}`}
        </>
      }
      onSubmit={handleSubmit}
      onBack={() => history.push(PATH.INSTALLATIONS.LIST)}
      unstyled
    >
      <FormInstallation installation={{ ...installation }} />
    </FormPage>
  );
}
