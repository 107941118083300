import EditIcon from '@material-ui/icons/Edit';
import React, { useMemo } from 'react';
import { NumberFormatCustom } from 'shared';
import { isSkeletonLoading } from 'shared';

export function usePlansBuildTable(plans, t, handleSelect) {
  const headCells = useMemo(() => {
    return [
      { id: 'planType', numeric: false, disablePadding: false, label: t('properties.name') },
      { id: 'baseRate', numeric: false, disablePadding: false, label: t('properties.baseRate') },
      { id: 'experienceRate', numeric: false, disablePadding: false, label: t('properties.experienceRate') },
      { id: 'edit', action: true }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!plans) {
      return [];
    }
    return plans.items.map(plan => ({
      id: plan.id,
      planType: plan.planType,
      baseRate: isSkeletonLoading(plan.baseRate)
        ? plan.baseRate
        : plan.baseRate && <NumberFormatCustom displayType={'text'} value={plan.baseRate.toFixed(2)} />,
      experienceRate: isSkeletonLoading(plan.experienceRate)
        ? plan.experienceRate
        : plan.experienceRate && <NumberFormatCustom displayType={'text'} value={plan.experienceRate.toFixed(2)} />,
      edit: {
        title: t('actions.edit'),
        cb: () => handleSelect(plan),
        icon: <EditIcon />
      }
    }));
  }, [handleSelect, plans, t]);

  return { headCells, rows };
}
