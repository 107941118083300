import { makeStyles } from '@material-ui/core/styles';

export const useToolbarContainerStyles = makeStyles(theme => ({
  blueContainer: {
    padding: theme.spacing(3),
    background: theme.palette.primary.light1,
    '& .MuiAccordionSummary-content':{
      margin: '0 !important'
    },
  },
  header: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    background: theme.palette.primary.lightest
  },
  title:{
    color: theme.palette.primary.main,
  },
  noMargin: {
    margin: 0,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderLeft: 'none'
  },
  content: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    flexGrow: 1
  },
  displayBlock:{
    display: 'block',
    float: 'left',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%'
  }
}));
