import { DialogModal, Summary } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { useHandleRequest } from 'shared/useHandleRequest';

import { useEditModalStyles } from './editModal.styles';

export function EditModal({
  entity,
  onSubmit,
  handleCloseEditModal,
  onSubmitEditModal,
  onKeepCreating,
  className,
  readonly,
  ...props
}) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useEditModalStyles();
  const $formRef = useRef(null);
  const [keepCreating, setKeepCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { request } = useHandleRequest();

  const handleAccept = keepCreating => {
    setKeepCreating(keepCreating);
    setSuccessMessage(null);
    $formRef.current.submit();
  };

  const handleSubmit = async element => {
    try {
      await request(async () => {
        const success = await onSubmitEditModal(element);
        if (!success) throw '';
        if (!keepCreating) {
          handleCloseEditModal();
        } else {
          setSuccessMessage(t('common:responses.addedSuccessfully'));
          onKeepCreating();
        }
      });
    } catch (error) {
      setSuccessMessage(null);
    }
  };

  const handleClose = () => {
    setSuccessMessage(null);
    handleCloseEditModal();
  };

  return (
    <>
      {entity && (
        <DialogModal
          fullWidth
          title={entity.id ? t('actions.edit') : t('actions.newone')}
          description=""
          isOpen={true}
          className={clsx(className, classes.modal)}
          onClose={handleClose}
          buttons={
            readonly
              ? [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  }
                ]
              : [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  },
                  {
                    children: <DoneIcon />,
                    text: t('common:actions.accept'),
                    type: 'primary',
                    onClick: () => handleAccept(false)
                  },
                  !entity.id && {
                    children: <DoneAll />,
                    text: t('common:actions.createMore'),
                    type: 'primary',
                    onClick: () => handleAccept(true)
                  }
                ]
          }
        >
          {Boolean(successMessage) && (
            <Summary text={successMessage} className={classes.successMessage} severity="success" />
          )}

          {React.cloneElement(props.children, {
            ...props.children,
            formRef: $formRef,
            onSubmit: handleSubmit
          })}
        </DialogModal>
      )}
    </>
  );
}

EditModal.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string
  }),
  requiredProps: PropTypes.array,
  successMessage: PropTypes.bool,
  handleCloseEditModal: PropTypes.func.isRequired,
  handleAcceptEditModal: PropTypes.func.isRequired,
  onSubmitEditModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorInfo: PropTypes.object,
  onKeepCreating: PropTypes.func,
  readonly: PropTypes.bool
};
