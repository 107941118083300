import { objectHelper } from 'shared/helper';
import { skeletonUser } from './skeletonUser';
import { skeletonEnum } from './skeletonEnum';
import { renderToString } from 'react-dom/server';
import { skeletonInstallation } from './skeletonInstallation';
import { skeletonPlan } from './skeletonPlan';
import { skeletonBillingProposal } from './skeletonBillingProposal';

export const SKELETON_KEY = 'skeleton_loading';
export const SKELETON_KEY_NUMBER = 901901901901;
export const SKELETON_KEY_DATE = 'May 1,1890 11:20:00';
export const SKELETON_KEY_BOOL = false;
export const SKELETON_ZERO = 0;

export function isSkeletonLoading(value) {
  if (objectHelper.isAnObject(value)) {
    return Object.keys(value).some(function (k) {
      return value[k] === SKELETON_KEY || value[k] === SKELETON_KEY_NUMBER || value[k] === SKELETON_KEY_DATE;
    });
  }

  return value === SKELETON_KEY || value === SKELETON_KEY_NUMBER || value === SKELETON_KEY_DATE;
}

export function containsSkeletonLoading(value) {
  var parsedValue = renderToString(value);
  return (
    parsedValue.includes(SKELETON_KEY) ||
    parsedValue.includes(SKELETON_KEY_DATE) ||
    parsedValue.includes(SKELETON_KEY_NUMBER)
  );
}

const pagination = { page: 0, pageSize: 2147483647, total: 3, totalPages: 1 };

export const skeletonFactory = {
  users: function users(num = 10) {
    const user = skeletonUser();
    return { ...pagination, items: Array(num).fill(user) };
  },
  user: function user() {
    return skeletonUser();
  },
  enumList: function enumList(num = 10) {
    const enumItem = skeletonEnum();
    return Array(num).fill(enumItem);
  },
  enumItem: function enumItem() {
    return skeletonEnum();
  },
  plans: function plans(num = 10) {
    const plan = skeletonPlan();
    return { ...pagination, items: Array(num).fill(plan) };
  },
  plan: function plan() {
    return skeletonPlan();
  },
  installations: function installations(num = 10) {
    const installation = skeletonInstallation();
    return { ...pagination, items: Array(num).fill(installation) };
  },
  installation: function installation() {
    return skeletonInstallation();
  },
  billingProposals: function billingProposals(num = 10) {
    const billingProposal = skeletonBillingProposal();
    return { ...pagination, items: Array(num).fill(billingProposal) };
  },
  billingProposal: function billingProposal() {
    return skeletonBillingProposal();
  },
};
