import { useState, useEffect, useCallback } from 'react';
import { billingProposals } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';

const billingProposalDummy = skeletonFactory.billingProposal();

const defaultBillingProposal = { lines: [] };

export function useBillingProposal(id) {
  const [billingProposalData, setBillingProposalData] = useState(id ? billingProposalDummy : { ...defaultBillingProposal });
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        const response = await billingProposals.getById(id);
        if (response) {
          setBillingProposalData(response.data || {});
        }
      }, false);
    }
    id && getData();
  }, [request, id]);

  return {
    billingProposal: billingProposalData,
    errorInfo,
    setErrorInfo,
    setBillingProposalData,
  };
}
