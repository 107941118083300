import { Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { isSkeletonLoading } from 'shared';

import { useListUsersStyles } from './listUsers.styles';

export function useUsersBuildTable(inputs, t) {
  const classes = useListUsersStyles();
  const history = useHistory();
  const headCells = useMemo(() => {
    return [
      {
        id: 'userName',
        numeric: false,
        disablePadding: true,
        label: t('properties.userName'),
        additionalProperty: {
          value: user =>
            !isSkeletonLoading(user.userName) && (
              <div className={classes.valignMiddle}>
                <Avatar src={`data:image/jpeg;base64,${user.avatar || ''}`} />
              </div>
            ),
          beforeParent: true
        },
        style: { minWidth: '20rem'}
      },
      { id: 'alias', numeric: false, disablePadding: false, label: t('properties.alias') },
      { id: 'edit', action: true}
    ];
  }, [classes.valignMiddle, t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(user => ({
      id: user.id,
      avatar: user.avatar,
      userName: user.userName,
      alias: user.alias,
      edit: {
        title: t('actions.edit'),
        cb: () => history.push(PATH.USERS.EDIT.replace(':id', user.id)),
        icon: <EditIcon />
      },
    }));
  }, [history, inputs, t]);

  return { headCells, rows };
}
