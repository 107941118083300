import React from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { billingProposals as billingProposalsService } from 'services';
import { PageHeader, ViewTable } from 'shared';
import { useViewTableData } from 'shared';
import { skeletonFactory } from 'shared';

import { useBillingProposalsBuildTable } from './useBillingProposalsBuildTable';

const billingProposalsDummy = skeletonFactory.billingProposals();

export function ListBillingProposals() {
  const { t } = useTranslation(NAME_SPACE.BILLING_PROPOSALS);

  const {
    data: billingProposals,
    get: getBillingProposals,
  } = useViewTableData({
    service: billingProposalsService,
    paginatedSkeleton: billingProposalsDummy
  });

  const { rows, headCells } = useBillingProposalsBuildTable(billingProposals, t);

  return (
    <>
      <PageHeader>{t('name')}</PageHeader>

      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={{ property: 'date', direction: 'asc' }}
        onFetchData={getBillingProposals}
        totalRows={billingProposals?.total}
        allowRowSelection={false}
        serverSidePaging
      />
    </>
  );
}
