import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { isSkeletonLoading } from 'shared';
import { rowFilterHelper } from 'shared';
import { NumberFormatCustom } from 'shared';

export const useViewBillingProposalLinesBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: t('lines.properties.description')
      },
      {
        id: 'quantity',
        numeric: false,
        disablePadding: true,
        label: t('lines.properties.quantity')
      },
      {
        id: 'amount',
        numeric: false,
        disablePadding: true,
        label: t('lines.properties.amount')
      },
      {
        id: 'total',
        numeric: false,
        disablePadding: true,
        label: t('lines.properties.total')
      },
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs.length
      ? []
      : inputs
      ? inputs.map(billingProposalLine => ({
          description: billingProposalLine.description,
          quantity: billingProposalLine.quantity,
          amount: isSkeletonLoading(billingProposalLine.amount)
            ? billingProposalLine.amount
            : billingProposalLine.amount && (
                <NumberFormatCustom displayType={'text'} value={Number(billingProposalLine.amount).toFixed(2)} />
              ),
          total: isSkeletonLoading(billingProposalLine.amount)
            ? billingProposalLine.amount
            : billingProposalLine.amount && (
                <NumberFormatCustom
                  displayType={'text'}
                  value={(billingProposalLine.amount * billingProposalLine.quantity).toFixed(2)}
                />
              )
        }))
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs.length, t]);

  const getBillingProposalLines = useCallback(
    ({ paging, sorting, filteredCells }) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filteredCells));
    },
    [initialRows]
  );

  return { headCells, rows, getBillingProposalLines };
};
