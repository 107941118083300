import { makeStyles } from '@material-ui/core/styles';

export const useNavigationListStyles = makeStyles(theme => ({
  navigation: {
    width: 370,
    zIndex: 0
  },
  root: {
    width: '100%',
    padding: 0
  },
  content: {
    flex: 1,
    padding: 0
  },
  noIcon: {
    paddingLeft: '3.3rem'
  },
  elementContentClose: {
    width: '4rem !important',

    '&:before': {
      left: '18% !important'
    }
  },
  wrapperIconText: {
    display: 'flex',
    marginLeft: '3rem'
  },
  tooltip: {
    zIndex: '9999'
  },
  elementContent: {
    marginLeft: '-13px',
    '&:before': {
      transition: theme.time.fast,
      left: '2.5rem',
      right: 0,
      height: '100%',
      width: '2px',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.basic.brighter
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '&:before': {
        backgroundColor: 'white',
        width: '4px'
      }
    }
  },
  elementText: {
    paddingLeft: '1rem',
    '& span': {
      fontSize: '12px !important'
    }
  },
  wrapperIconTextClose: {
    marginLeft: '0.5rem'
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '0px 12px 12px 0px',
    minHeight: 'auto !important',
    padding: '0px 13px',
    '& .Mui-expanded':{
      margin: 'inherit !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiSvgIcon-root':{
        color: 'white'
      }
    },
    '& .MuiIconButton-edgeStart': {
      transform: 'rotate(-90deg) !important',
      right: '-10px',
      margin: '0'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg) !important'
    }
  },
  highlighted: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      backgroundColor: 'white',
      width: '4px'
    }
  },
  navContentOpen: {
    overflowX:'hidden',
    width: '190px',
    overflow: 'hidden',
    flexGrow: '0 !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& .MuiSvgIcon-root':{
      color: 'white'
    }
  },
  navContentClose: {
    width: '24px',
    overflow: 'hidden',
    flexGrow: '0 !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  expandIcon: {
    color: 'white'
  },
  heading: {
    fontSize: '0.8rem',
    alignSelf: 'center',
    marginLeft: '0.5rem'
  }
}));
