import React from 'react';
import { useAccountContainerStyles } from './accountContainer.styles';
import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as HgTagLogo } from './../assets/images/hgtag-logo.svg';

export function AccountContainer(pageComponent) {
  return function ContainerWrapper() {
    const classes = useAccountContainerStyles();

    return (
      <Grid container spacing={0} style={{ height: '100%' }}>
        <Grid item xs={12} className={[classes.contentCentered, classes.credentialsContainer]}>
          <SvgIcon component={HgTagLogo} viewBox="-1 0 210 102" class={classes.hgtagIcon} />
          {pageComponent}
        </Grid>
      </Grid>
    );
  };
}
