import { useCallback, useState, useRef } from 'react';
import { useHandleRequest } from './useHandleRequest';
import { useStateWithBackup } from './useStateWithBackup';
import { useTranslation } from 'react-i18next';
import { useHandleOpen } from '@engloba-tech/englobity';
import { useRequestParams } from 'shared';

export function useViewTableData({ service, paginatedSkeleton = {} }) {
  const { t } = useTranslation();
  const [data, setData, undoData] = useStateWithBackup(paginatedSkeleton); // should be paginated
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { paging, sorting, filteredCells, advancedFilters, currentRequestParams, setRequestParams } = useRequestParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);

  const keepAdding = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const get = useCallback(
    async ({paging, sorting, filteredCells, advancedFilters}) => {
      await request(async () => {
        setRequestParams(paging, sorting, filteredCells, advancedFilters);
        const response = await service.get(paging, sorting, filteredCells, advancedFilters);
        if (response) {
          setData(response.data || []);
        }
      }, false);
    },
    [request, setRequestParams, service, setData]
  );

  const exportElements = useCallback(
    async (headCells, fileName, advancedFilters) => {
      await request(async () => {
        const response = await service.export(sorting, filteredCells, advancedFilters);
        if (response.data) {
          generateAndDownloadCSV(headCells, response.data.items, fileName);
        }
      }, false);
    },
    [filteredCells, request, service, sorting]
  );

  const deleteElements = useCallback(
    async elementsToDelete => {
      await request(async () => {
        await Promise.allSettled(
          elementsToDelete.map(element => {
            setData(prevElement => {
              prevElement.items = prevElement.items.map(w => {
                if (!elementsToDelete.find(o => o.id === w.id)) {
                  return w;
                }
                return paginatedSkeleton.items[0];
              });
              return { ...prevElement };
            });
            return service.delete(element.id);
          })
        ).then(responses => {
          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoData();
            setErrorInfo({ message: t(JSON.parse(responses[0].reason.response.data).message) });
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            get(paging, sorting);
            setErrorInfo({
              message: `${t('deletionWithErrors')} ${t(
                JSON.parse(responses.find(resp => resp.status === 'rejected').reason.response.data).message
              )}`,
              severity: 'warning'
            });
          } else {
            get({paging, sorting, filteredCells, advancedFilters});
          }
        });
      });
    },
    [
      service,
      paginatedSkeleton,
      get,
      paging,
      request,
      setData,
      sorting,
      setErrorInfo,
      t,
      undoData,
      filteredCells,
      advancedFilters
    ]
  );

  const generateAndDownloadCSV = (headers, rows, fileName) => {
    const csvHeaders = [headers.map(cell => cell.label)].map(e => e.join(';'));
    const csvRows = rows
      .map(row => headers.map(cell => (cell.translate ? t(row[cell.id]) : row[cell.id])))
      .map(e => e.join(';'))
      .join('\n');
    let csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvHeaders + '\n' + csvRows);
    var link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}_${new Date().toLocaleDateString()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleSelectedItem = id => {
    setSelectedItem(id ? data.items.find(item => item.id === id) : {});
    setErrorInfo(null);
    handleOpen();
  };

  const handleCloseEditModal = useCallback(() => {
    handleClose();
    setSelectedItem(null);
    setSuccessMessage(null);
    setErrorInfo(null);
  }, [handleClose, setErrorInfo]);

  const handleAcceptEditModal = useCallback(createMore => {
    keepAdding.current = createMore;
  }, []);

  // const onSubmitEditModal = useCallback(
  //   async item => {
  //     setSuccessMessage(null);
  //     setSelectedItem(item);
  //     await request(async () => {
  //       const success = !item.id ? await service.create(item) : await service.update(item);
  //       if (success) {
  //         if (keepAdding.current) {
  //           setSelectedItem({});
  //           setSuccessMessage(
  //             item.id ? t('common:responses.editedSuccesfully') : t('common:responses.addedSuccessfully')
  //           );
  //         } else {
  //           handleCloseEditModal();
  //         }
  //         get(paging, sorting, filteredCells, advancedFilters);
  //       }
  //     }, false);
  //   },
  //   [advancedFilters, filteredCells, get, handleCloseEditModal, paging, request, service, sorting, t]
  // );

  const activateDesactivateItem = useCallback(
    async itemRow => {
      try {
        await request(async () => {
          setData(prevItems => ({
            ...prevItems,
            items: prevItems.items.map(prevItem =>
              prevItem.id === itemRow.id ? { ...prevItem, ...itemRow, isDisabled: !itemRow.isActive } : prevItem
            )
          }));
          itemRow.isActive ? await service.activate(itemRow.id) : await service.desactivate(itemRow.id);
        });
      } catch (error) {
        undoData();
      }
    },
    [request, service, setData, undoData]
  );

  return {
    data,
    get,
    deleteElements,
    exportElements,
    errorInfo,
    setErrorInfo,
    setData,
    undoData,
    paging,
    sorting,
    filteredCells,
    selectedItem,
    editModalOpen: isOpen,
    handleSelectedItem,
    handleCloseEditModal,
    handleAcceptEditModal,
    // onSubmitEditModal,
    successMessage,
    activateDesactivateItem,
    currentRequestParams
  };
}
