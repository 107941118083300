import { http } from './http';

class Auth {
  login(user) {
    // dont works in dev
    // return http.post('/authentication/token', user, { withCredentials: true }, null, false);
    return http.post('/authentication/token', user, {}, null, false);
  }

  refreshToken(refreshToken) {
    // dont works in dev
    return http.post('/authentication/refresh-token', { refreshToken }, {}, null, false);
    // return http.post('/authentication/refresh-token', {}, { withCredentials: true }, null, false);
  }

  logout(refreshToken) {
    return http.post('/authentication/revoke-token', { refreshToken }, {}, null, false);
    // return http.post('/authentication/revoke-token', {}, { withCredentials: true }, null, false);
  }

  changePassword(request) {
    return http.post('/authentication/change-password', request, {});
  }

  forceChangePassword(request) {
    return http.post('/authentication/force-change-password', request, {});
  }

  forgotPassword(email) {
    return http.post('/authentication/forgot-password', { email }, {});
  }

  async checkTokenValidity(email, token) {
    var result =  await http.post('/authentication/check-token-password', { email, token }, {});
    return result && result.data;
  }

  resetPassword(email, token, newPassword) {
    return http.post('/authentication/reset-password', { email, token, newPassword }, {});

  }
}

export const auth = new Auth();
