export const resources = {
  en: {
    common: require('./en/common.json'),
    authentication: require('./en/authentication.json'),
    users: require('./en/users.json'),
    plans: require('./en/plans.json'),
    installations: require('./en/installations.json'),
    billingProposals: require('./en/billingProposals.json')
  },
  es: {
    common: require('./es/common.json'),
    authentication: require('./es/authentication.json'),
    users: require('./es/users.json'),
    plans: require('./es/plans.json'),
    installations: require('./es/installations.json'),
    billingProposals: require('./es/billingProposals.json')
  },
  ca: {
    common: require('./ca/common.json'),
    authentication: require('./ca/authentication.json'),
    users: require('./ca/users.json'),
    plans: require('./ca/plans.json'),
    installations: require('./ca/installations.json'),
    billingProposals: require('./ca/billingProposals.json')
  }
};
