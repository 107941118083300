import React, { useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { PATH } from 'app.routes.const';

export function useInstallationsBuildTable(installations, t) {
  const history = useHistory();
  const headCells = useMemo(() => {
    return [
      { id: 'name', numeric: false, disablePadding: false, label: t('properties.name') },
      { id: 'clientCode', numeric: false, disablePadding: false, label: t('properties.clientCode') },
      { id: 'activePlan', numeric: false, disablePadding: false, label: t('properties.activePlan') },
      { id: 'edit', action: true }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!installations) {
      return [];
    }
    return installations.items.map(installation => ({
      id: installation.id,
      name: installation.name,
      clientCode: installation.clientCode,
      activePlan: installation.activePlan,
      edit: {
        title: t('actions.edit'),
        cb: () => history.push(PATH.INSTALLATIONS.EDIT.replace(':id', installation.id)),
        icon: <EditIcon />
      }
    }));
  }, [history, installations, t]);

  return { headCells, rows };
}
