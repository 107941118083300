import { ButtonAdd } from '@engloba-tech/englobity';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';
import { useButtonStyles } from '../Button/button.styles';

export function ClientManagementButtonAdd({ disabled, condition, tooltip, text, onClick, requiredAccess, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const { hasFullAccess } = useContext(UserContext);
  const classes = useButtonStyles();

  const renderButton = () => {
    return (
      <ButtonAdd
        disabled={disabled}
        className={classes.buttonCommonStyles}
        text={text || t('actions.add')}
        tooltip={{
          title: (disabled && tooltip?.disabled) || '',
          placement: 'top'
        }}
        onClick={onClick}
      />
    );
  };

  return requiredAccess ? hasFullAccess(requiredAccess) && renderButton() : hasFullAccess() && renderButton();
}

ClientManagementButtonAdd.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string
  }),
  requiredProps: PropTypes.array,
  successMessage: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  handleCloseEditModal: PropTypes.func.isRequired,
  handleAcceptEditModal: PropTypes.func.isRequired,
  onSubmitEditModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorInfo: PropTypes.object
};
