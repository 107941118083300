import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonAdd,
  ButtonDelete,
  EditModal,
  ToolbarContainer,
  useCheckedElements,
  useSelectedElement,
  ViewTable
} from 'shared';
import { errorToaster } from 'shared';
import { FormInstallationPlan } from './FormInstallationPlan';
import { useViewInstallationPlansBuildTable } from './useViewInstallationPlansBuildTable';

export function ViewInstallationPlans({ installationPlans, onAdd, onUpdate, onDelete }) {
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);
  const initialSorting = { property: 'startDate', direction: 'asc' };

  const { selectedElement: selectedInstallationPlan, handleNew, handleSelect, handleUnselect } = useSelectedElement();
  const { rows, headCells, getInstallationPlans } = useViewInstallationPlansBuildTable(
    installationPlans,
    t,
    handleSelect,
    initialSorting
  );

  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    isAnyChecked,
    onCheckElement,
    onCheckAllElements
  } = useCheckedElements({
    t,
    mainKey: 'plan',
    prefix: 'installationPlans',
    action: onDelete
  });
  const isEditing = Boolean(selectedInstallationPlan?.id);

  function checkInstallationPlansOverlapping(installationPlans) {
    for (let i = 0; i < installationPlans.length - 1; i += 1) {
      for (let j = i + 1; j < installationPlans.length; j += 1) {
        if (
          dateRangeOverlaps(
            installationPlans[i].startDate,
            installationPlans[i].finishDate,
            installationPlans[j].startDate,
            installationPlans[j].finishDate
          )
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function dateRangeOverlaps(date1Start, date1End, date2Start, date2End) {
    if (date1End == null && (date2Start >= date1Start || date2End >= date1Start)) return true;
    if (date2End == null && (date1Start >= date2Start || date1End >= date2Start)) return true;
    if (date1Start <= date2Start && date2Start <= date1End) return true;
    if (date1Start <= date2End && date2End <= date1End) return true;
    if (date2Start < date1Start && date1End < date2End) return true;
    return false;
  }

  function handleInstallationPlansSubmit(installationPlan, isEditing) {
    if (installationPlan.startDate > installationPlan.finishDate) {
      errorToaster(t('validations.invalidDates'));
      return false;
    }

    const finalInstallationPlans = isEditing
      ? installationPlans.map(ip => (ip.id === installationPlan.id ? installationPlan : ip))
      : [...installationPlans, installationPlan];

    if (finalInstallationPlans.filter(ip => !ip.finishDate) > 1) {
      errorToaster(t('validations.onlyOneFinishDateNullable'));
      return false;
    }

    const datesOverlaps = checkInstallationPlansOverlapping(finalInstallationPlans);
    if (datesOverlaps) {
      errorToaster(t('validations.datesOverlaps'));
      return false;
    }

    if (isEditing) {
      onUpdate(installationPlan);
    } else {
      onAdd(installationPlan);
    }
    return true;
  }

  return (
    <>
      <ToolbarContainer
        title={t('properties.installationPlans')}
        actions={
          <>
            <ButtonAdd onClick={handleNew} disabled={isAnyChecked()}></ButtonAdd>
            <ButtonDelete
              elementsToDelete={checkedElements}
              confirmDescription={getDeleteDescription}
              onConfirm={handleCheckedElements}
            ></ButtonDelete>
          </>
        }
      >
        <ViewTable
          rows={rows}
          cells={headCells}
          onFetchData={getInstallationPlans}
          defaultOrderBy={initialSorting}
          onCheckElement={onCheckElement}
          onCheckAllElements={onCheckAllElements}
          checkedElements={checkedElements}
        />
      </ToolbarContainer>

      <EditModal
        entity={selectedInstallationPlan}
        handleCloseEditModal={handleUnselect}
        onSubmitEditModal={installationPlan => handleInstallationPlansSubmit(installationPlan, isEditing)}
        onKeepCreating={handleNew}
      >
        <FormInstallationPlan installationPlan={selectedInstallationPlan} />
      </EditModal>
    </>
  );
}

ViewInstallationPlans.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  installationPlans: PropTypes.array
};
