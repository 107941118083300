import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { authStorage } from 'auth';
import { auth, users } from 'services';
import { authHelper, useHandleRequest } from 'shared';

export const UserContext = React.createContext({});

function userInfoMapper(user) {
  return {
    alias: user.alias,
    avatar: user.avatar,
    exp: user.exp,
    roles: user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    name: user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
    access: user.fullAccess | user.readOnlyAccess,
    fullAccess: user.fullAccess,
    readOnlyAccess: user.readOnlyAccess,
    id: user.sub
  };
}

// TODO: tests
export function useUserContext() {
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const [user, setUser] = useState({});
  const [authToken, setAuthToken] = useState('');
  const [isLogged, setIsLogged] = useState(!!authStorage.getToken());
  const [isLoading, setIsLoading] = useState(true);
  const [externalLoginError, setExternalLoginError] = useState(null);
  const [currentPathAccess, setCurrentPathAccess] = useState(null);

  const history = useHistory();

  const clear = useCallback(() => {
    authStorage.setToken('');
    authStorage.setRefreshToken('');
    setAuthToken('');
    setUser('');
    setIsLogged(false);
    setExternalLoginError(null);
    history.push(PATH.AUTHENTICATION.LOGIN);
  }, [history]);

  useEffect(() => {
    async function getData() {
      await request(async () => {
        try {
          const token = authStorage.getToken();

          if (token) {
            const userInfo = authHelper.claimDeserializer(token);
            const user = userInfoMapper(userInfo);
            const response = await users.getMy();
            setUser({ ...user, ...(response?.data || {}) });
            setAuthToken(token);
            setIsLogged(!!token);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          clear();
        }
      }, false);
    }
    getData();
  }, [clear, request]);

  const setUserLogged = useCallback(
    async _userTokenData => {
      authStorage.setToken(_userTokenData.token);
      authStorage.setRefreshToken(_userTokenData.refreshToken);
      setAuthToken(_userTokenData.token);
      const userInfo = authHelper.claimDeserializer(_userTokenData.token);
      const user = userInfoMapper(userInfo);

      const responseUser = await users.getMy();
      setUser({ ...user, ...(responseUser?.data || {}) });
      setIsLogged(true);
      setErrorInfo(null);
      setExternalLoginError(null);
    },
    [setErrorInfo]
  );

  const login = useCallback(
    async _user => {
      await request(async () => {
        const response = await auth.login(_user);
        await setUserLogged(response.data);
      });
    },
    [request, setUserLogged]
  );

  const logout = useCallback(
    async user => {
      await request(async () => {
        const refreshToken = authStorage.getRefreshToken();
        auth.logout(refreshToken);
        clear();
      });
    },
    [request, clear]
  );

  const externalLogin = useCallback(async () => {
    const tokenResult = {
      token: new URLSearchParams(window.location.search).get('token'),
      refreshToken: new URLSearchParams(window.location.search).get('refreshToken')
    };

    if (tokenResult.token && tokenResult.refreshToken) {
      await setUserLogged(tokenResult);
      return tokenResult;
    }

    const errorResult = { message: new URLSearchParams(window.location.search).get('message') };
    if (errorResult) setExternalLoginError(errorResult);
  }, [setUserLogged]);

  const hasFullAccess = (path) => {
    if (!path) return !currentPathAccess ? true : Boolean(user.fullAccess & currentPathAccess);
    return path && currentPathAccess ? Boolean(user.fullAccess & (path || currentPathAccess)) : true;
  };

  return {
    user,
    authToken,
    isLogged,
    isLoading,
    setUser,
    setAuthToken,
    setIsLogged,
    login,
    logout,
    externalLogin,
    errorInfo,
    externalLoginError,
    setErrorInfo,
    setCurrentPathAccess,
    currentPathAccess,
    hasFullAccess
  };
}
