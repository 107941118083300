import React from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { DialogModal } from '@engloba-tech/englobity';
import globalStyles from 'styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

export function execConfirmModal({ title, description, confirmText, declineText, onConfirm }) {
  return new Promise((resolve, reject) => {
    const idGenerated = shortid.generate();
    const entryPointToasterDom = document.createElement('div');
    entryPointToasterDom.setAttribute('id', idGenerated);

    const global = document.getElementById('global');
    global.appendChild(entryPointToasterDom);

    ReactDOM.render(
      <ThemeProvider theme={globalStyles}>
        <CssBaseline />
        <DialogModal
          fullWidth
          title={title}
          description={description}
          isOpen
          buttons={[
            {
              children: <CloseIcon />,
              type: 'secondary',
              text: declineText,
              onClick: () => {
                ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                global.removeChild(entryPointToasterDom);
                reject('User clicked No');
              }
            },
            {
              children: <DoneIcon />,
              type: 'primary',
              text: confirmText,
              onClick: () => {
                ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                global.removeChild(entryPointToasterDom);
                onConfirm && onConfirm();
                resolve('User clicked Yes');
              }
            }
          ]}
          onClose={() => {
            ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
            global.removeChild(entryPointToasterDom);
          }}
        />
      </ThemeProvider>,
      document.getElementById(idGenerated)
    );
  });
}
