import React from 'react';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { usContainerStyles } from './container.styles';

export function Container(pageComponent) {
  return function ContainerWrapper() {
    const classes = usContainerStyles();
    return (
      <>
        <div className={classes.root}>
          <Navigation />
          <main className={classes.content}>
            <Header />
            <div className={classes.pageContainer}>{pageComponent}</div>
          </main>
        </div>
      </>
    );
  };
}
