import { useCallback, useState } from 'react';

export function useSelected({
  data = [],
  keyName = 'id',
  initialSelected = [],
  selectWhenCondition = null,
  onCheckElement
}) {
  const [selectedData, setSelectedData] = useState(initialSelected);

  const setSelectToggleAll = useCallback(() => {
    let newSelected = data;
    if (selectedData.length) {
      newSelected = [];
    }
    if (selectWhenCondition) {
      newSelected = newSelected.filter(element => selectWhenCondition(element));
    }
    newSelected = newSelected.map(n => n[keyName]);

    setSelectedData(newSelected);
    onCheckElement && onCheckElement(newSelected.map(s => data.find(d => d[keyName] === s)));
  }, [data, keyName, selectWhenCondition, selectedData.length, onCheckElement]);

  const setSelected = useCallback(
    key => {
      const selectedIndex = selectedData.indexOf(key);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedData, key);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedData.slice(1));
      } else if (selectedIndex === selectedData.length - 1) {
        newSelected = newSelected.concat(selectedData.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedData.slice(0, selectedIndex), selectedData.slice(selectedIndex + 1));
      }
      setSelectedData(newSelected);
      onCheckElement(newSelected.map(s => data.find(d => d[keyName] === s)));
    },
    [data, keyName, selectedData, onCheckElement]
  );

  const cleanAllSelected = useCallback(() => {
    setSelectedData([]);
  }, []);

  const getFullObjectSelected = useCallback(() => {
    return selectedData.map(s => data.find(d => d[keyName] === s));
  }, [data, keyName, selectedData]);

  return { selected: selectedData, setSelected, setSelectToggleAll, getFullObjectSelected, cleanAllSelected };
}
