function asyncFileReader(file) {
  return new Promise((resolve, reject) => {
    let tag = file.name;
    const index = tag.lastIndexOf('.');
    if (index > 0) tag = tag.substring(0, index);
    const reader = new FileReader();

    reader.onload = () => {
      let content = reader.result.replace(/^data:(.*;base64,)?/, '');

      if (content.length % 4 > 0) {
        content += '='.repeat(4 - (content.length % 4));
      }
      resolve({
        ...file,
        originalFilename: file.name,
        content: content,
        mimeType: file.name.endsWith('.msg') ? 'application/vnd.ms-outlook' : file.type, // WIP - workaround
        tag: file && file.tag ? file.tag : tag
      });
    };

    reader.readAsDataURL(file);
    reader.onabort = reject;
    reader.onerror = reject;
  });
}

function asyncImgReader(file) {
  return new Promise((resolve, reject) => {
    let tag = file.name;
    const index = tag.lastIndexOf('.');
    if (index > 0) tag = tag.substring(0, index);

    const img = new Image();
    img.onload = event => {
      const elem = document.createElement('canvas');
      const originalWidth = event.currentTarget.naturalWidth;
      const originalHeight = event.currentTarget.naturalHeight;
      const aspectRatio = Math.min(100 / originalWidth, 100 / originalHeight);
      elem.width = originalWidth * aspectRatio;
      elem.height = originalHeight * aspectRatio;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, elem.width, elem.height);
      ctx.canvas.toBlob(
        async blob => {
          const _file = new File([blob], tag, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });

          const readerFileCompresed = new FileReader();

          readerFileCompresed.onload = () => {
            let content = readerFileCompresed.result.replace(/^data:(.*;base64,)?/, '');

            if (content.length % 4 > 0) {
              content += '='.repeat(4 - (content.length % 4));
            }

            resolve({
              ...file,
              originalFilename: file.name,
              content: content,
              mimeType: file.type,
              tag: file && file.tag ? file.tag : tag
            });
          };

          readerFileCompresed.readAsDataURL(_file);
          readerFileCompresed.onabort = reject;
          readerFileCompresed.onerror = reject;
        },
        'image/jpeg',
        1
      );
    };

    const objectURL = URL.createObjectURL(file);
    img.src = objectURL;
    img.onabort = reject;
    img.onerror = reject;
  });
}

function readFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async file => {
      resolve(file.target.result);
    };
    reader.onabort = reject;
    reader.onerror = reject;
  });
}

export const fileHelper = {
  asyncFileReader,
  asyncImgReader,
  readFileReader
};
