import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import LRUCache from 'lru-cache';
import { configureRequestInterceptor, configureResponseInterceptor } from './interceptors';
import { authStorage } from 'auth';
import { authHelper } from 'shared';
import { auth } from 'services';

const THROTTLE_TIME = 500;
const DEFAULT_CACHE_TIME = 5000;

function configureInstance() {
  return {
    headers: { 'Cache-Control': 'no-cache' },
    adapter: throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter, {
        defaultCache: new LRUCache({ maxAge: DEFAULT_CACHE_TIME }),
        enabledByDefault: false,
        cacheFlag: 'cache'
      }),
      { threshold: THROTTLE_TIME }
    )
  };
}

function configureInterceptors(instance) {
  configureRequestInterceptor(instance);
  configureResponseInterceptor(instance);
}

class Http {
  constructor() {
    const axiosOptions = configureInstance();

    this.instance = axios.create(axiosOptions);
    configureInterceptors(this.instance);
  }

  setBaseUrls(urls) {
    this.baseUrls = urls;
  }

  getBaseUrl(api) {
    return (
      (api && this.baseUrls.filter(url => url.key === api)[0] && this.baseUrls.filter(url => url.key === api)[0].url) ||
      this.baseUrls[0].url
    );
  }

  setOnUnauthorized(cb) {
    this.onUnauthorized = cb;
  }

  async getBearerToken(config = {}) {
    try {
      let token = authStorage.getToken();
      if (!token) {
        this.onUnauthorized && this.onUnauthorized();
      }

      if (authHelper.checkIfTokenIsExpired(token)) {
        const refreshToken = authStorage.getRefreshToken();
        const response = await auth.refreshToken(refreshToken);
        token = response.data.token;
        authStorage.setToken(token);
        authStorage.setRefreshToken(response.data.refreshToken);
      }

      const authorization = `Bearer ${token}`;

      return {
        ...config,
        headers: { Authorization: authorization, ...config.headers }
      };
    } catch (error) {
      this.onUnauthorized && this.onUnauthorized();
    }
  }

  async get(url, config = {}, api = null) {
    return this.call(this.instance.get(this.getBaseUrl(api) + url, await this.getBearerToken(config)));
  }

  async delete(url, config, api = null) {
    return this.call(this.instance.delete(this.getBaseUrl(api) + url, await this.getBearerToken(config)));
  }

  async post(url, data, config, api = null, secured = true) {
    let configCall;
    if (secured) {
      configCall = await this.getBearerToken(config);
    } else {
      configCall = config;
    }

    return this.call(this.instance.post(this.getBaseUrl(api) + url, data, configCall));
  }

  async put(url, data, config, api = null, secured = true) {
    let configCall;
    if (secured) {
      configCall = await this.getBearerToken(config);
    } else {
      configCall = config;
    }

    return this.call(this.instance.put(this.getBaseUrl(api) + url, data, configCall));
  }

  async patch(url, data, config, api = null) {
    return this.call(this.instance.patch(this.getBaseUrl(api) + url, data, await this.getBearerToken(config)));
  }

  call(action) {
    this.isBaseUrl();
    return action;
  }

  isBaseUrl() {
    if (!this.baseUrls) {
      throw new Error('baseUrls is empty, use setBaseUrls(urls: [string])');
    }
  }
}

export const http = new Http();
