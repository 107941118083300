import { makeStyles } from '@material-ui/core';
import backgroundImage from './../assets/images/home-hgtag-academy.jpg';

export const useAccountContainerStyles = makeStyles(theme => ({
  hgtagIcon:{
    height: '11em'
  },
  contentCentered: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-webkit-autofill::first-line' : {
      fontFamily: 'Mulish'
    },
    '& .MuiInputLabel-outlined': {
      backgroundColor: theme.palette.secondary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4d5df1 !important'
    }
  },
  credentialsContainer: {
    backgroundImage:  `url(${backgroundImage})`,
    color: 'white'
  },
  '.MuiTypography-root': {
    fontFamily: 'Mulish'
  }
}));
