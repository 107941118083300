import { Form, Input } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { NAME_SPACE } from 'i18n';
import { FormContainer, isSkeletonLoading, useFormState } from 'shared';

import { ViewInstallationPlans } from '../ViewInstallationPlans';

export function FormInstallation({ formRef, onSubmit, installation, onChangeInput }) {
  const [inputs, setInputs] = useFormState({ ...installation } || {}, onChangeInput);
  const { t } = useTranslation(NAME_SPACE.INSTALLATIONS);

  function handleSubmit() {
    onSubmit(inputs);
  }

  useEffect(() => {
    setInputs({ ...installation }, false);
  }, [installation]);

  function handleInstallationPlanAdd(instalationPlan) {
    setInputs(prevInputs => ({
      ...prevInputs,
      installationPlans: [...prevInputs.installationPlans, { ...instalationPlan, id: uuidv4() }]
    }));
  }

  function handleInstallationPlanDelete(installationPlansToDelete) {
    setInputs(prevInputs => {
      let newInstallationPlans = prevInputs.installationPlans;
      installationPlansToDelete.forEach(userId => {
        newInstallationPlans = newInstallationPlans.filter(user => user.id !== userId.id);
      });

      return {
        ...prevInputs,
        installationPlans: newInstallationPlans
      };
    });
  }

  function handleInstallationPlanUpdate(installationPlan) {
    setInputs(prev => ({
      ...prev,
      installationPlans: prev.installationPlans.map(ip => {
        if (ip.id === installationPlan.id) {
          return installationPlan;
        }
        return ip;
      })
    }));
  }

  return (
    <>
      <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <FormContainer grow={false}>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.name)}
              required
              disabled
              name={t('properties.name')}
              label={t('properties.name')}
              variant="outlined"
              value={inputs.name || ''}
              inputProps={{ 'aria-label': t('properties.name') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.clientCode)}
              required
              disabled
              name={t('properties.clientCode')}
              label={t('properties.clientCode')}
              variant="outlined"
              value={inputs.clientCode || ''}
              inputProps={{ 'aria-label': t('properties.clientCode') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(inputs.apiUrl)}
              required
              disabled
              name={t('properties.apiUrl')}
              label={t('properties.apiUrl')}
              variant="outlined"
              value={inputs.apiUrl || ''}
              inputProps={{ 'aria-label': t('properties.apiUrl') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
        </FormContainer>
        <FormContainer>
          <Grid item xs={12}>
            <ViewInstallationPlans
              installationPlans={inputs?.installationPlans}
              onAdd={handleInstallationPlanAdd}
              onUpdate={handleInstallationPlanUpdate}
              onDelete={handleInstallationPlanDelete}
            />
          </Grid>
        </FormContainer>
      </Form>
    </>
  );
}

FormInstallation.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  installation: PropTypes.shape(),
  createMode: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func
};
