import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { users as usersService } from 'services';
import {
  ButtonAdd,
  ButtonDelete,
  PageHeader,
  skeletonFactory,
  Toolbar,
  useCheckedElements,
  useViewTableData,
  ViewTable
} from 'shared';

import { useUsersBuildTable } from './useUsersBuildTable';

const usersDummy = skeletonFactory.users();

export function ListUsers() {
  const {
    data: users,
    get: getUsers,
    deleteElements: deleteUsers
  } = useViewTableData({
    service: usersService,
    paginatedSkeleton: usersDummy
  });
  const { t } = useTranslation(NAME_SPACE.USERS);
  const history = useHistory();

  const { rows, headCells } = useUsersBuildTable(users, t);
  const { checkedElements, getDeleteDescription, handleCheckedElements, onCheckElement, isAnyChecked, onCheckAllElements } =
    useCheckedElements({
      t,
      mainKey: 'userName',
      action: deleteUsers
    });

  return (
    <>
      <PageHeader>
        {t('name')}
        <Toolbar>
          <ButtonAdd onClick={() => history.push(PATH.USERS.CREATE)} disabled={isAnyChecked()} />
          <ButtonDelete
            elementsToDelete={checkedElements}
            confirmDescription={getDeleteDescription}
            onConfirm={handleCheckedElements}
          />
        </Toolbar>
      </PageHeader>

      <ViewTable
        rows={rows}
        cells={headCells}
        defaultOrderBy={'userName'}
        onFetchData={getUsers}
        totalRows={users.total}
        serverSidePaging
        onCheckElement={onCheckElement}
        onCheckAllElements={onCheckAllElements}
        checkedElements={checkedElements}
      />
    </>
  );
}
