import { lessProgress, moreProgress, stopProgress } from './progress';
import { execToaster, TOASTER_TYPES } from 'shared';
import i18n from 'i18n';
import { PATH } from 'app.routes.const';
import { stringHelper } from 'shared';

function splitAndTranslateEach(value = '') {
  if (value.includes('/nl')) {
    return value.split('/nl').map(item => `- ${i18n.t(item)}`);
  }
  return i18n.t(value);
}

export function configureRequestInterceptor(instance) {
  instance.interceptors.request.use(
    async config => {
      moreProgress();

      if (!config.headers.Accept) config.headers.Accept = 'application/json';
      if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';

      return config;
    },
    error => {
      execToaster({ name: i18n.t('request.error'), text: error.message, type: TOASTER_TYPES.ERROR });
      throw error;
    }
  );
}

export function configureResponseInterceptor(instance) {
  instance.interceptors.response.use(
    async response => {
      lessProgress();
      if (response.config.method !== 'get') {
        execToaster({
          name: i18n.t('request.succes'),
          text: i18n.t('request.expected'),
          type: TOASTER_TYPES.SUCCESS
        });
      }

      return response;
    },
    error => {
      if (!window.location.href.includes(PATH.AUTHENTICATION.LOGIN)) {
        if (error.response?.status === 403){
          error.message = i18n.t('common:validations:noWritePermission');
        }

        let errorMsg = null;
        if (error?.response?.data) {
          errorMsg = JSON.parse(
            error.response ? (error.response.data ? error.response.data : error.message) : error.message
          );
        } else {
          errorMsg = { message: error.message, detail: '' };
        }

        const errorDetail = errorMsg.detail ? stringHelper.normalizeQuotes(errorMsg.detail) : '';

        execToaster({
          name: `${i18n.t(stringHelper.normalizeQuotes(errorMsg.message))} ${splitAndTranslateEach(stringHelper.normalizeQuotes(errorDetail))}`,
          text: `${i18n.t(stringHelper.normalizeQuotes(errorMsg.message))} ${splitAndTranslateEach(stringHelper.normalizeQuotes(errorDetail))}`,
          type: TOASTER_TYPES.ERROR
        });
      }
      stopProgress();
      throw error;
    }
  );
}
