import { makeStyles } from '@material-ui/core/styles';

export const usePageHeaderStyles = makeStyles(theme => ({
  pageHeader: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(3),
    justifyContent: 'space-between'
  }
}));
