import { apiHelper } from 'shared';

import { http } from './http';

const controllerName = 'installations';

class Installations {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(installation) {
    return http.post(`/${controllerName}`, installation, {});
  }

  delete(id) {
    return http.delete(`/${controllerName}/${id}`, {});
  }

  update(installation) {
    return http.put(`/${controllerName}/${installation.id}`, installation, {});
  }
}

export const installations = new Installations();
