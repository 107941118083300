import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { TableCell, TextField } from '@material-ui/core';
import { Select } from '@engloba-tech/englobity';
import { LocaleDatePicker } from 'shared';
import { FILTER_TYPE, FILTER_SUFFIX } from './advancedFilters.const';
import { useAdvancedFiltersStyles } from './advancedFilters.styles';
import { useTranslation } from 'react-i18next';
import ChipInput from 'material-ui-chip-input';

function FilterSwitch({ cell, onChange }) {
  const classes = useAdvancedFiltersStyles();
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({});

  const booleanElements = useMemo(() => {
    const TEXTS = {
      true: 'true',
      false: 'false'
    };

    var booleanMapped = Object.keys(TEXTS).map(key => {
      return {
        value: key,
        name: t(TEXTS[key])
      };
    });

    return [{ value: -1, name: t('common:selectFirstOptionComboFilter') }, ...booleanMapped];
  }, [t]);

  switch (cell.filterType) {
    case FILTER_TYPE.NONE:
      return null;
    case FILTER_TYPE.DATE:
      return (
        <div className={classes.dateWrapper}>
          <LocaleDatePicker
            clearable
            name={cell.id}
            className={`${classes.date} ${classes.dateLeft}`}
            value={inputs[cell.id + FILTER_SUFFIX.START]}
            onChange={e => {
              const pickerName = cell.id + FILTER_SUFFIX.START;
              const value = e.target.value ? e.target.value.utc().format('YYYY-MM-DDTHH:mm:ss') : null;
              setInputs(prev => ({ ...prev, [pickerName]: value }));
              onChange(pickerName, value);
            }}
            inputVariant="standard"
            TextFieldComponent={params => <TextField placeholder={t('common:actions.from')} {...params} />}
          />
          <div className={classes.dateSlash}></div>
          <LocaleDatePicker
            clearable
            name={cell.id}
            className={`${classes.date} ${classes.dateRight}`}
            value={inputs[cell.id + FILTER_SUFFIX.END]}
            onChange={e => {
              const pickerName = cell.id + FILTER_SUFFIX.END;
              const value = e.target.value ? e.target.value.utc().format('YYYY-MM-DDTHH:mm:ss') : null;
              setInputs(prev => ({ ...prev, [pickerName]: value }));
              onChange(pickerName, value);
            }}
            inputVariant="standard"
            TextFieldComponent={params => <TextField placeholder={t('common:actions.until')} {...params} />}
          />
        </div>
      );
    case FILTER_TYPE.COMBO:
      return (
        <Select
          elements={cell.filterValues}
          title={''}
          displayEmpty
          variant={'standard'}
          style={{ margin: 0 }}
          value={inputs[cell.filterBy || cell.id] || cell.filterValues[0]?.value}
          onChange={e => {
            setInputs(prev => ({ ...prev, [cell.filterBy || cell.id]: e.target.value }));
            onChange(cell.filterBy || cell.id, e.target.value);
          }}
        />
      );
    case FILTER_TYPE.CHIP:
      return (
        <ChipInput
          defaultValue={inputs[cell.filterBy || cell.id] || []}
          onChange={value => {
            setInputs(prev => ({ ...prev, [cell.filterBy || cell.id]: value }));
            onChange(cell.filterBy || cell.id, value);
          }}
          fullWidth
        />
      );

    case FILTER_TYPE.BOOLEAN:
      return (
        <Select
          elements={booleanElements}
          title={''}
          displayEmpty
          variant={'standard'}
          style={{ margin: 0 }}
          value={inputs[cell.filterBy || cell.id] || booleanElements[0]?.value}
          onChange={e => {
            setInputs(prev => ({ ...prev, [cell.filterBy || cell.id]: e.target.value }));
            onChange(cell.filterBy || cell.id, e.target.value);
          }}
        />
      );
    default:
      return <TextField fullWidth onChange={e => onChange(cell.id, e.target.value)} />;
  }
}

FilterSwitch.propTypes = {
  cell: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

function _advancedFilters({ cells, onFilterCellChange }) {
  return (
    <>
      {cells &&
        cells.length &&
        cells.map(cell => {
          return (
            <TableCell key={shortid.generate()} component="th">
              {cell.label && <FilterSwitch cell={cell} onChange={onFilterCellChange} />}
            </TableCell>
          );
        })}
    </>
  );
}

export const AdvancedFilters = React.memo(_advancedFilters);

_advancedFilters.propTypes = {
  cells: PropTypes.array.isRequired,
  onFilterCellChange: PropTypes.func.isRequired
};
