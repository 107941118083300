import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { UserContext, useUserContext } from 'auth';
import { http } from 'services/http';
import { Routes } from './App.routes';
import { LoadingPage } from 'pages';
import { UserStateContext, useUserStateContext } from 'state/useUserStateContext';

function App() {
  const userContext = useUserContext();
  const userStateContext = useUserStateContext();

  http.setOnUnauthorized(() => userContext.setIsLogged(false));

  return (
        <UserContext.Provider value={userContext}>
          <UserStateContext.Provider value={userStateContext}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {userContext.isLoading ? (
                <LoadingPage />
              ) : (
                <Routes isLogged={userContext.isLogged} user={userContext.user} setCurrentPathAccess={userContext.setCurrentPathAccess} />
              )}
            </MuiPickersUtilsProvider>
          </UserStateContext.Provider>
        </UserContext.Provider>
  );
}

export default App;
