import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from './assets/locales';

export const NAME_SPACE = {
  COMMON: 'common',
  AUTHENTICATION: 'authentication',
  PLANS: 'plans',
  INSTALLATIONS: 'installations',
  BILLING_PROPOSALS: 'billingProposals',
  USERS: 'users'
};

export const LANGUAGE = {
  ENGLISH: 'en',
  SPANISH: 'es',
  CATALAN: 'ca'
};

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [LANGUAGE.ENGLISH, LANGUAGE.SPANISH],
    ns: [
      NAME_SPACE.COMMON,
      NAME_SPACE.AUTHENTICATION,
      NAME_SPACE.PLANS,
      NAME_SPACE.INSTALLATIONS,
      NAME_SPACE.BILLING_PROPOSALS,
      NAME_SPACE.USERS
    ],
    defaultNS: NAME_SPACE.COMMON,
    fallbackNS: NAME_SPACE.COMMON,
    react: {
      wait: true,
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
