import { Avatar } from '@engloba-tech/englobity';
import { Toolbar } from '@material-ui/core';
import { Container, Paper } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { UserContext } from 'auth';
import { UserStateContext } from 'state/useUserStateContext';

import { useHeaderStyles } from './header.styles';

export function Header() {
  const classes = useHeaderStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const { user, logout } = useContext(UserContext);
  const { clearUserState } = useContext(UserStateContext);
  const name = user.alias;
  const email = user.name || user.userName || '';

  const handleLogout = () => {
    logout();
    clearUserState();
  };

  return (
    <Paper elevation={1} className={classes.topBar}>
      <Container maxWidth={false} className={classes.backgroundImage}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.grow} />
          <Avatar
            logOutText={t('navigation.logOut')}
            onLogOut={handleLogout}
            userName={name}
            userThumbnail={`data:image/jpeg;base64,${user.avatar}`}
            userEmail={email}
            myAccountText={t('navigation.myAccount')}
            onMyAccountClick={() => history.push(PATH.USERS.PROFILEEDIT.replace(':id', user.id))}
          />
        </Toolbar>
      </Container>
    </Paper>
  );
}
