import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { settings, KEY_SETTINGS } from './settings';
import { http } from './services/http';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import globalStyles from './styles';
import 'poppins-font';
import 'nprogress/nprogress.css';
import './i18n';
import { APIS } from 'app.apis.const';

async function startUp() {
  /**
   * Here we are fetching settings
   * set BaseUrl for the Static HTTP
   */
  await settings.loadSettings();

  const clientManagementApiURL = `${settings.getSetting(KEY_SETTINGS.API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.API_VERSION
  )}`;

  http.setBaseUrls([
    { key: APIS.CLIENT_MANAGEMENT, url: clientManagementApiURL }
  ]);
}

(async () => {
  try {
    await startUp();
    ReactDOM.render(
      <BrowserRouter>
        <ThemeProvider theme={globalStyles}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>,
      document.getElementById('root')
    );
  } catch (error) {
    ReactDOM.render(
      <React.StrictMode>
        <dir>{error}</dir>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
