import { SKELETON_KEY, SKELETON_KEY_DATE } from './skeletonFactory';

function installationPlan() {
  return {
    planType: SKELETON_KEY,
    planId: SKELETON_KEY,
    baseRate: SKELETON_KEY,
    experienceRate: SKELETON_KEY,
    description: SKELETON_KEY,
    startDate: SKELETON_KEY_DATE,
    finishDate: SKELETON_KEY_DATE
  };
}

export function skeletonInstallation() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    clientCode: SKELETON_KEY,
    activePlan: SKELETON_KEY,
    installationPlans: Array(10).fill(installationPlan())
  };
}
