import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Container } from 'layout';
import { AccountContainer } from 'layout';

import { PATH } from './app.routes.const';
import {
  EditBillingProposal,
  EditInstallation,
  EditUser,
  Error,
  ListPlans,
  ListUsers,
  Login,
} from './pages';
import { ListInstallations } from 'pages/Installations';
import { ListBillingProposals } from 'pages/BillingProposals';

const _routes = ({ isLogged }) => {
  const GuardedRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          isLogged === true ? (
            <Component {...props} />
          ) : (
            <Redirect to={`${PATH.AUTHENTICATION.LOGIN}?returnUrl=${rest.location.pathname}`} />
          )
        }
      />
    );
  };

  return (
    <Switch>
      <Route path={PATH.ERROR} component={Error} />
      <Route exact path={PATH.AUTHENTICATION.LOGIN} component={AccountContainer(<Login />)} />

      <GuardedRoute exact path={PATH.PLANS.LIST} component={Container(<ListPlans />)} />
      <GuardedRoute exact path={PATH.INSTALLATIONS.LIST} component={Container(<ListInstallations />)} />
      <GuardedRoute exact path={PATH.INSTALLATIONS.EDIT} component={Container(<EditInstallation />)} />
      <GuardedRoute exact path={PATH.BILLING_PROPOSALS.LIST} component={Container(<ListBillingProposals />)} />
      <GuardedRoute exact path={PATH.BILLING_PROPOSALS.EDIT} component={Container(<EditBillingProposal />)} />

      <GuardedRoute exact path={PATH.USERS.LIST} component={Container(<ListUsers />)} />
      <GuardedRoute exact path={PATH.USERS.CREATE} component={Container(<EditUser />)} />
      <GuardedRoute exact path={PATH.USERS.EDIT}  component={Container(<EditUser />)} />
      <GuardedRoute exact path={PATH.USERS.PROFILEEDIT} component={Container(<EditUser profile />)} />

      <Redirect from={PATH.HOME} to={PATH.PLANS.LIST} />
    </Switch>
  );
};

export const Routes = React.memo(_routes);

_routes.propTypes = {
  isLogged: PropTypes.bool.isRequired
};
