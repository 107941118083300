function getFilteredRows(initialRows, filters, sorting) {
  if (!Object.keys(filters).length) {
    return initialRows;
  }

  const filterFields = Object.keys(filters);
  let filteredRows = [...initialRows];
  filterFields.forEach(field => {
    filteredRows = filteredRows.filter(row =>
      row[field].props
        ? row[field].props.value.toString().replace('.', ',').includes(filters[field])
        : filters[field]
        ? row[field].toString().toLowerCase().includes(filters[field].toLowerCase())
        : null
    );
  });
  return filteredRows.sort((a,b) => a[sorting.orderBy] < b[sorting.orderBy] ? 1 : -1);
}

export const rowFilterHelper = {
  getFilteredRows
};
