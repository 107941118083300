import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Input, TextArea } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { NumberFormatCustom } from 'shared';

export function FormPlan({ formRef, onSubmit, plan }) {
  const [inputs, setInputs] = useState({ ...plan } || {});
  const { t } = useTranslation(NAME_SPACE.PLANS);

  function handleSubmit() {
    onSubmit(inputs);
  }

  useEffect(() => {
    setInputs({ ...plan });
  }, [plan]);

  const handleChange =
    fieldName =>
    ({ target }) => {
      setInputs(prevInputs => ({
        ...prevInputs,
        [fieldName]: target.value
      }));
    };

  return (
    <>
      <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(inputs.planType)}
              required
              disabled
              name={t('properties.planType')}
              label={t('properties.name')}
              variant="outlined"
              value={inputs.planType || ''}
              onChange={handleChange('planType')}
              inputProps={{ 'aria-label': t('properties.planType') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.baseRate)}
              onChange={handleChange('baseRate')}
              name={t('properties.baseRate')}
              label={t('properties.baseRate')}
              variant="outlined"
              InputProps={{ inputComponent: NumberFormatCustom }}
              value={inputs.baseRate ? Number(inputs.baseRate).toFixed(2) : inputs.baseRate}
              required
              validators={['required', 'minNumber: 0']}
              errorMessages={[t('validations.required'), t('validations.negativeAmountsNotAccepted')]
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.experienceRate)}
              onChange={handleChange('experienceRate')}
              name={t('properties.experienceRate')}
              label={t('properties.experienceRate')}
              variant="outlined"
              InputProps={{ inputComponent: NumberFormatCustom }}
              value={inputs.experienceRate ? Number(inputs.experienceRate).toFixed(2) : inputs.experienceRate}
              required
              validators={['required', 'minNumber: 0']}
              errorMessages={[t('validations.required'), t('validations.negativeAmountsNotAccepted')]
              }
            />
          </Grid>
          <Grid item xs={12}>
              <TextArea
                isLoading={isSkeletonLoading(inputs.description)}
                onChange={handleChange('description')}
                label={t('properties.description')}
                value={inputs.description || ''}
                name={t('properties.description')}
                rows={4}
              />
            </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormPlan.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  plan: PropTypes.object
};
