import { SKELETON_KEY } from './skeletonFactory';

function line() {
  return {
    description: SKELETON_KEY,
    quantity: SKELETON_KEY,
    amount: SKELETON_KEY
  };
}

export function skeletonBillingProposal() {
  return {
    id: SKELETON_KEY,
    installation: SKELETON_KEY,
    date: SKELETON_KEY,
    total: SKELETON_KEY,
    lines: Array(10).fill(line())
  };
}
