import { Form, Input } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';
import { FormContainer, isSkeletonLoading, useFormState } from 'shared';

import { ViewBillingProposalLines } from '../ViewBillingProposalLines';
import { LocaleDatePicker } from 'shared';
import { DateRange } from '@material-ui/icons';
import { NumberFormatCustom } from 'shared';

export function FormBillingProposal({ formRef, onSubmit, billingProposal, onChangeInput }) {
  const [inputs, setInputs] = useFormState({ ...billingProposal } || {}, onChangeInput);
  const { t } = useTranslation(NAME_SPACE.BILLING_PROPOSALS);

  function handleSubmit() {
    onSubmit(inputs);
  }

  useEffect(() => {
    setInputs({ ...billingProposal }, false);
  }, [billingProposal]);

  return (
    <>
      <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
        <FormContainer grow={false}>
          <Grid item xs={6}>
            <Input
              isLoading={isSkeletonLoading(inputs.installation)}
              required
              disabled
              name={t('properties.installation')}
              label={t('properties.installation')}
              variant="outlined"
              value={inputs.installation || ''}
              inputProps={{ 'aria-label': t('properties.installation') }}
              validators={['required', 'maxStringLength:255']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            />
          </Grid>
          <Grid item xs={6}>
            <LocaleDatePicker
              isLoading={isSkeletonLoading(inputs.date)}
              required
              disabled
              icon={<DateRange />}
              name={t('properties.date')}
              label={t('properties.date')}
              value={inputs.date}
              inputProps={{ 'aria-label': 'date' }}
              validators={['required']}
              errorMessages={[t('validations.required')]}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(inputs.total)}
              name={t('properties.total')}
              disabled
              label={t('properties.total')}
              variant="outlined"
              InputProps={{ inputComponent: NumberFormatCustom }}
              value={inputs.total ? Number(inputs.total).toFixed(2) : ''}
              required
              validators={['required', 'minNumber: 0']}
              errorMessages={[t('validations.required'), t('validations.negativeAmountsNotAccepted')]}
            />
          </Grid>
        </FormContainer>
        <FormContainer>
          <Grid item xs={12}>
            <ViewBillingProposalLines billingProposalLines={inputs?.lines} />
          </Grid>
        </FormContainer>
      </Form>
    </>
  );
}

FormBillingProposal.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  billingProposal: PropTypes.shape(),
  onChangeInput: PropTypes.func
};
