import React from 'react';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import InboxIcon from '@material-ui/icons/Inbox';
import ComputerIcon from '@material-ui/icons/Computer';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const PATH = {
  HOME: '/',
  AUTHENTICATION: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password'
  },
  PLANS: {
    LIST: '/plans'
  },
  INSTALLATIONS: {
    LIST: '/installations',
    EDIT: '/installation/:id'
  },
  BILLING_PROPOSALS: {
    LIST: '/billing-proposals',
    EDIT: '/billing-proposal/:id'
  },
  USERS: {
    LIST: '/users',
    EDIT: '/user/:id',
    PROFILEEDIT: '/profileuser/:id',
    CREATE: '/user',
  },
  ERROR: '/error'
};

export const ICONS = {
  PLANS: <InboxIcon/>,
  INSTALLATIONS: <ComputerIcon/>,
  BILLING_PROPOSALS: <ReceiptIcon/>,
  USERS: <GroupOutlinedIcon />
};
