import { NAME_SPACE } from 'i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarContainer, ViewTable } from 'shared';
import { useViewBillingProposalLinesBuildTable } from './useViewBillingProposalLinesBuildTable';

export function ViewBillingProposalLines({ billingProposalLines }) {
  const { t } = useTranslation(NAME_SPACE.BILLING_PROPOSALS);

  const { rows, headCells, getBillingProposalLines } = useViewBillingProposalLinesBuildTable(billingProposalLines, t);

  return (
    <ToolbarContainer title={t('properties.lines')}>
      <ViewTable rows={rows} cells={headCells} onFetchData={getBillingProposalLines} allowRowSelection={false} />
    </ToolbarContainer>
  );
}

ViewBillingProposalLines.propTypes = {
  billingProposalLines: PropTypes.array
};
