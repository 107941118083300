// TODO: spread obj as a string value, {workOrderName: 'patter', startDate: 'start', startDate: 'end'}}}

function mapObjectToQueryString(object) {
  return Object.keys(object)
    .filter(key => object[key] || !isNaN(parseInt(object[key])) || typeof object[key] === 'boolean')
    .map(key => {
      if (Array.isArray(object[key])) {
        if (object[key].length && typeof object[key][0] === 'object') {
          return object[key]
            .map((k, index) =>
              Object.keys(k)
                .map(prop => {
                  return `${key}[${index}].${prop}=${k[prop]}`;
                })
                .join('&')
            )
            .join('&');
        } else {
          return object[key].map(k => key + '=' + k).join('&');
        }
      }
      return key + '=' + object[key];
    })
    .join('&');
}

function composeQueryString(params) {
  let queryString = '';

  if (params) {
    queryString = mapObjectToQueryString(params);

    if (queryString.length > 0) {
      queryString = `?${queryString}`;
    }
  }
  return queryString;
}

export const apiHelper = { composeQueryString };
