import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { dateHelper } from 'shared';
import { isSkeletonLoading } from 'shared';
import { rowFilterHelper } from 'shared';
import EditIcon from '@material-ui/icons/Edit';
import { NumberFormatCustom } from 'shared';

export const useViewInstallationPlansBuildTable = (inputs, t, handleSelect, sorting) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'plan',
        numeric: false,
        disablePadding: true,
        label: t('installationPlans.properties.plan')
      },
      {
        id: 'startDate',
        numeric: false,
        disablePadding: true,
        label: t('installationPlans.properties.startDate')
      },
      {
        id: 'finishDate',
        numeric: false,
        disablePadding: true,
        label: t('installationPlans.properties.finishDate')
      },
      { id: 'baseRate', numeric: false, disablePadding: false, label: t('installationPlans.properties.baseRate') },
      { id: 'experienceRate', numeric: false, disablePadding: false, label: t('installationPlans.properties.experienceRate') },
      {
        id: 'edit',
        action: true
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs.length
      ? []
      : inputs
      ? inputs.map(installationPlan => ({
          id: installationPlan.id,
          plan: installationPlan.plan,
          planId: installationPlan.planId,
          startDate: isSkeletonLoading(installationPlan.startDate)
            ? installationPlan.startDate
            : dateHelper.toShortDate(installationPlan.startDate.toString()),
          finishDate: isSkeletonLoading(installationPlan.finishDate)
            ? installationPlan.finishDate
            : (installationPlan.finishDate && dateHelper.toShortDate(installationPlan.finishDate.toString())) || '-',
          baseRate: isSkeletonLoading(installationPlan.baseRate)
            ? installationPlan.baseRate
            : installationPlan.baseRate && (
                <NumberFormatCustom displayType={'text'} value={Number(installationPlan.baseRate).toFixed(2)} />
              ),
          experienceRate: isSkeletonLoading(installationPlan.experienceRate)
            ? installationPlan.experienceRate
            : installationPlan.experienceRate && (
                <NumberFormatCustom displayType={'text'} value={Number(installationPlan.experienceRate).toFixed(2)} />
              ),
          edit: {
            title: t('actions.edit'),
            cb: () => handleSelect(installationPlan),
            icon: <EditIcon />
          }
        }))
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped.sort((a,b) => dateHelper.convertToDate(a.startDate) < dateHelper.convertToDate(b.startDate) ? 1 : -1));
  }, [inputs, inputs.length, sorting.orderBy, t]);


  const getInstallationPlans = useCallback(
    ({ paging, sorting, filteredCells }) => {
      setRows(rowFilterHelper.getFilteredRows(rows, filteredCells, sorting));
    },
    [initialRows]
  );

  return { headCells, rows, getInstallationPlans };
};
