import { makeStyles } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles(theme => ({
  toolbar: {
    float: 'right',
    // marginBottom: theme.spacing(3),
    '& > *':{
      float: 'left',
      marginLeft: '1rem'
    }
  }
}));
