import { color } from './color.styles';

export const globals = {
  html: {
    fontSize: '18px', // 1rem = 16px,
    height: '100%'
  },
  body: {
    '& *': {
      fontFamily: 'Mulish'
    },
    fontFamily: 'Mulish',
    fontWeight: '300',
    backgroundColor: color.primary.lighter,
    height: '100%',
    '& .MuiGrid-root.MuiGrid-container': {
      // height: '100%'
    }
  },
  '.MuiTooltip-popper': {
    '& .MuiTooltip-tooltip': {
      fontSize: '0.875rem !important',
      fontFamily: 'Mulish !important'
    }
  },
  '.MuiButton-label': {
    fontFamily: 'Mulish'
  },
  '.MuiInputBase-root': {
    fontSize: 'inherit',
    '&:hover': {
      '&.Mui-error':{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color.action.error
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color.primary.main,
      }
    }
  },
  '.MuiInputLabel-outlined': {
    backgroundColor: 'white',
    padding: '0 0.5rem 0 0.2rem'
  },
  '.MuiTypography-root': {
    fontFamily: 'Mulish'
  },
  '#alert_title': {
    fontWeight: 'bold',
    marginBottom: '0'
  },
  '#nprogress': {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: '9999',
    bar: {
      zIndex: '9999',

      backgroundColor: color.primary.main
    },
    peg: {
      zIndex: '9999',

      boxShadow: 'none'
    }
  },
  '.MuiAlert-root': {
    padding: '0px 15px !important',
    lineHeight: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row !important',
    // marginBottom: '24px'
  },
  '.MuiAlert-standardInfo': {
    padding: '0px 15px !important',
    backgroundColor: color.primary.light,
    color: color.primary.main,
    '& .MuiAlert-icon': {
      color: color.primary.main
    }
    // border: '1px solid #b0ddff'
  },
  '.MuiAlert-standardWarning': {
    padding: '0px 15px !important',
    border: '1px solid #ffcf88'
  },
  '.MuiAlert-standardSuccess': {
    padding: '0px 15px !important',
    border: '1px solid #7bd76a'
  },
  '.MuiAlert-standardError': {
    alignItems: 'center',
    padding: '0px 15px !important',
    color: 'white',
    backgroundColor: color.toast.error,
    '& .MuiAlert-icon': {
      color: 'white'
    },
    '& .MuiAlert-message': {
      '& p': {
        color: 'white !important',
        fontWeight: 'bold !important'
      }
    }
  },
  '.MuiCardContent-root': {
    '& .MuiCollapse-entered': {
      marginBottom: '20px'
    }
  },
  '.MuiToolbar-root': {
    minHeight: 'auto'
  },
  '.MuiPaper-elevation1.MuiPaper-rounded': {
    boxShadow: color.boxShadow,
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  '.MuiTypography-h6': {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  '.MuiTableFooter-root': {
    backgroundColor: color.table.summary
  },
  '.MuiTableCell-root': {
    '& .MuiCollapse-root': {
      marginTop: '5px'
    },
    // padding: '5px 15px !important',

    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSwitch-thumb': {
      marginTop: '9px'
    },
    '& .MuiSwitch-colorPrimary': {
      '&.Mui-checked': {
        marginLeft: '15px'
      }
    }
  },

  '.viewtable': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  '.MuiDialog-paperScrollPaper': {
    display: 'flex',
    maxHeight: 'none',
    padding: '0',
    '& .viewtable': {
      '& > .MuiPaper-root': {
        paddingBottom: 0,
        marginBottom: 0
      }
    },
    '& .MuiGrid-root.MuiGrid-container': {
      height: 'auto'
    },
    '& .MuiAlert-root': {
      display: 'flex',
      alignItems: 'center',
      '& h6': {
        fontSize: '13px'
      }
    },
    '&.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth': {
      width: 'auto',
      '& .MuiDialogActions-root': {
        marginTop: '1.5rem'
      },
      '& h2': {
        textAlign: 'center',
        fontWeight: 'bold'
      }
    },
    '& .MuiPaper-root': {
      boxShadow: 'none'
    },
    '& .MuiCollapse-root': {
      '&.MuiCollapse-hidden': {
        marginBottom: '0px'
      }
    }
  },
  '.MuiAppBar-root': {
    padding: '0 !important'
  },
  '.MuiTab-root': {
    height: 'auto !important'
  },
  '.react-swipeable-view-container': {
    '& div[data-swipeable=true]': {
      overflow: 'hidden !important'
    }
  },
  '.MuiBox-root': {
    '& .MuiPaper-root': {
      '& .MuiAlert-standardInfo.MuiAlert-standardWarning.MuiAlert-standardError': {
        '& .MuiAlert-icon': {
          marginLeft: '10px'
        },
        '& .MuiAlert-action': {
          marginRight: '6px'
        }
      }
    },
    '& .viewtable': {
      paddingBottom: 0,
      '& > .MuiPaper-root': {
        padding: '0 !important'
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: color.primary.main,
  },
  '.MuiDialogContent-root': {
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
    '& .viewtable': {
      padding: 0
    }
  },
  '.MuiDialogContentText-root': {
    fontSize: '15px',
    marginBottom: '1.5rem !important',
    textAlign: 'center'
  },
  '.react-bpmn-diagram-container *': {
    fontFamily: 'inherit'
  },

  '.MuiLink-root': {
    cursor: 'pointer'
  },
  '.djs-label': {
    '& tspan': {
      fontFamily: 'PT Sans Caption, sans-serif, arial, Times New Roman'
    }
  },
  '.MuiChip-root': {
    borderRadius: '8px !important'
  },
  '.MuiDialogTitle-root': {
    marginBottom: '1.5rem !important'
  },
  // '.MuiCollapse-entered':{
  //   marginBottom: '0 !important'
  // }
};
