import { apiHelper } from 'shared';

import { http } from './http';

const controllerName = 'billing-proposals';

class BillingProposals {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({ ...paging, ...sorting, ...filters });

    return http.get(`/${controllerName}${queryString}`, {});
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`, {});
  }

  create(billingProposal) {
    return http.post(`/${controllerName}`, billingProposal, {});
  }

  delete(id) {
    return http.delete(`/${controllerName}/${id}`, {});
  }

  update(billingProposal) {
    return http.put(`/${controllerName}/${billingProposal.id}`, billingProposal, {});
  }
}

export const billingProposals = new BillingProposals();
