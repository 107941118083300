import { DialogModal, Summary, useHandleOpen } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { PATH } from 'app.routes.const';
import { ICONS } from 'app.routes.const';
import { UserContext } from 'auth';
import { NAME_SPACE } from 'i18n';
import { FormPage, useRequestLoading } from 'shared';
import { isSkeletonLoading } from 'shared';
import { Button } from 'shared';

import { useEditUserStyles } from './editUser.styles';
import { FormChangePassword } from './FormChangePassword';
import { FormUser } from './FormUser';
import { useUser } from './useUser';

export function EditUser({ profile }) {
  const [updatedUser, setUpdatedUser] = useState(false);
  const { id } = useParams();
  const { user, saveUser, changePassword, changePasswordSuccess, setChangePasswordSuccess } = useUser(id, profile);
  const { t } = useTranslation(NAME_SPACE.USERS);
  const history = useHistory();
  const { callbackRequest } = useRequestLoading();
  const { user: userFromContext, setUser } = useContext(UserContext);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formChangePassword = useRef(null);
  const classes = useEditUserStyles();

  const isProfile = profile === true;

  async function handleSubmitOnProfile(user) {
    await callbackRequest(async () => {
      const userToUpdate = !isProfile ? { ...user, avatar: userFromContext.avatar } : user;
      await saveUser(userToUpdate);
      !isProfile && history.push(PATH.USERS.LIST);
      setUser({
        ...user,
        access: userFromContext.access,
        fullAccess: userFromContext.fullAccess,
        readOnlyAccess: userFromContext.readOnlyAccess
      });
    });
  }

  async function handleSubmit(user) {
    await callbackRequest(async () => {
      const newId = await saveUser(user);
      setUpdatedUser(t('saveWarning'));
      history.push(PATH.USERS.EDIT.replace(':id', newId));
    });
  }

  function handleOpenChangePasswordModal() {
    setChangePasswordSuccess(false);
    handleOpen();
  }

  async function onChangePassword(request) {
    var success = await changePassword(request);
    if (success) {
      setChangePasswordSuccess(t('actions.changePassword.success'));
      handleClose();
    }
  }

  const isCreating = !id;

  return (
    <>
      <FormPage
        title={
          <>
            {ICONS.USERS.EDIT}&nbsp;&nbsp;
            {t('singularName')}
            {!isSkeletonLoading(user.alias) && user.alias && user.id && ` | ${user.alias}`}
          </>
        }
        extraActions={
          <>
            {(!isCreating || isProfile) && (
              <Button
                icon={<VpnKeyIcon />}
                text={t('actions.changePassword.title')}
                onClick={handleOpenChangePasswordModal}
              />
            )}
          </>
        }
        onSubmit={isProfile ? handleSubmitOnProfile : handleSubmit}
        onBack={!isProfile ? () => history.push(PATH.USERS.LIST) : null}
      >
        <Summary text={updatedUser} severity="warning" />

        <Summary text={changePasswordSuccess} severity="success" />

        <FormUser user={{ ...user }} createMode={isCreating} profile={profile} />
      </FormPage>

      <DialogModal
        title={t('actions.changePassword.title')}
        description={isProfile ? t('actions.changePassword.description') : t('actions.changePassword.descriptionNoProfile')}
        fullWidth
        isOpen={isOpen}
        onClose={handleClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.close'),
            type: 'secondary',
            onClick: handleClose
          },
          {
            children: <SaveAltIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: () => $formChangePassword.current.submit()
          }
        ]}
      >
        <FormChangePassword formRef={$formChangePassword} onSubmit={onChangePassword} isProfile={isProfile} />
      </DialogModal>
    </>
  );
}

EditUser.propTypes = {
  profile: PropTypes.bool
};
