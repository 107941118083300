import { Summary } from '@engloba-tech/englobity';
import { Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import WarningRounded from '@material-ui/icons/WarningRounded';
import { Rating, Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import { UserContext } from 'auth';
import { BooleanIcon } from 'shared/BooleanIcon';
import { color } from 'styles/color.styles';

import { isSkeletonLoading } from '../skeletonFactory';
import { useSelected } from '../useSelected';
import { AdvancedFilters } from './AdvancedFilters';
import { ViewTableFooter } from './Footer';
import { ViewTableSummary } from './FooterSummary';
import { ViewTableHead } from './Head';
import { usePagination } from './usePagination';
import { getComparator, stableSort } from './viewTable.helper';
import { useViewTableStyles } from './viewTable.styles';

export function _viewTable({
  errors,
  rows,
  viewTableRef,
  cells,
  onFetchData,
  serverSidePaging = false,
  totalRows,
  elevation = false,
  spaced = false,
  allowRowSelection,
  allowPaging = true,
  defaultOrderBy,
  emptyText,
  disableOrderBy,
  toolbar,
  checkRowWhen,
  actions,
  allowRowFilter,
  summaryCells,
  editModal,
  onCheckElement,
  checkedElements,
  onCheckAllElements,
  onlyRows = false
}) {
  const { selected, setSelected, setSelectToggleAll, getFullObjectSelected, cleanAllSelected } = useSelected({
    data: rows,
    selectWhenCondition: checkRowWhen,
    onCheckElement
  });

  if (viewTableRef) {
    viewTableRef.current = { selected: getFullObjectSelected() };
  }

  const { hasFullAccess } = useContext(UserContext);

  const isPaginatedTable = serverSidePaging && !!onFetchData;

  const mapCellWithActions = useMemo(() => {
    return actions
      ? [
          ...cells,
          ...actions.map(ac => ({
            id: ac.name,
            numeric: false,
            disablePadding: false,
            label: ac.formatAsColumn ? ac.label : '',
            formatAsColumn: ac.formatAsColumn
          }))
        ]
      : cells;

    // WARNING: dont add actions as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells]);

  const { order, orderBy, page, rowsPerPage, changeSort, changePage, changeRows, changeFilter } = usePagination(
    cells,
    onFetchData,
    isPaginatedTable,
    defaultOrderBy || (disableOrderBy ? '' : cells[0].id)
  );

  const classes = useViewTableStyles();
  const { t } = useTranslation();

  async function handleDelete() {
    const allObjectsSelected = getFullObjectSelected();
    await toolbar?.onDelete(allObjectsSelected);
    cleanAllSelected();
  }

  async function handleMultipleUpdate() {
    const allObjectsSelected = getFullObjectSelected();
    await toolbar?.onMultipleUpdate(allObjectsSelected);
    cleanAllSelected();
  }

  function getVisibleRows(rows) {
    if (allowPaging && serverSidePaging) return rows;

    let sortedRows = stableSort(rows, getComparator(order, orderBy));
    if (allowPaging) {
      sortedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return sortedRows;
  }

  function getEmptyRows() {
    const rowsWithValueCount = serverSidePaging ? rows.length : rows.length - page * rowsPerPage;
    return rowsPerPage - Math.min(rowsPerPage, rowsWithValueCount);
  }

  const isSelected = name => {
    // return checkedElements?.indexOf(name) !== -1;
    return checkedElements?.some(element => element.id === name);
  };

  const isLoading = rows && !rows.length;
  const emptyRows = getEmptyRows();
  const totalRowCount = serverSidePaging ? totalRows : rows.length;
  const isAnySelected = checkedElements?.length > 0;

  const ableSelectRow = element => {
    if (checkRowWhen) {
      return checkRowWhen(element);
    }

    return allowRowSelection;
  };

  useEffect(() => {
    if (page > 0 && !rows.length) changePage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length]);

  const renderAction = (action, row) => {
    let actionToPrint;

    if (action.condition && action.condition(row)) {
      actionToPrint = action.whenConditionTrue;
    } else {
      actionToPrint = action;
    }

    if (actionToPrint) {
      const params = (actionToPrint.properties && actionToPrint.properties.map(property => row[property])) || [];

      return (
        <Tooltip className={classes.tooltip} title={actionToPrint.title}>
          {actionToPrint.children ? (
            actionToPrint.children
          ) : !params?.some(p => !p) ? (
            <IconButton
              onClick={() => !isSkeletonLoading(params[0]) && actionToPrint.cb && actionToPrint.cb(params)}
              aria-label={actionToPrint.label}
            >
              {actionToPrint.icon}
            </IconButton>
          ) : (
            <></>
          )}
        </Tooltip>
        // </TableCell>
      );
    } else return <TableCell key={shortid.generate()} padding="checkbox"></TableCell>;
  };

  return (
    <div className={`${classes.root} viewtable`}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby={toolbar?.title || ''}
          stickyHeader
          size="medium"
          aria-label="enhanced table"
        >
          {!onlyRows && (
            <ViewTableHead
              classes={classes}
              cells={mapCellWithActions}
              numSelected={checkedElements?.length}
              order={order}
              disableOrderBy={disableOrderBy}
              orderBy={orderBy}
              onSelectAllClick={() =>
                rows.some(row => row.canBeDeleted != null)
                  ? onCheckAllElements(rows?.filter(row => row.canBeDeleted))
                  : onCheckAllElements(rows)
              }
              onRequestSort={changeSort}
              rowCount={totalRowCount}
              allowRowSelection={allowRowSelection}
            />
          )}
          {/* Tr can't appear as a child of table BUT if thead is used, when scrolling down
             the list, filters will stick instead of column names*/}
          {allowRowFilter && !onlyRows && (
            <TableRow className={classes.filterRow}>
              {allowRowSelection && (
                <TableCell className={classes.filterCell}>
                  <FilterListIcon />
                </TableCell>
              )}

              <AdvancedFilters cells={mapCellWithActions} onFilterCellChange={changeFilter} />
            </TableRow>
          )}
          {!rows.length ? (
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: 'none', background: 'white' }} colSpan="100%">
                  <Summary className={classes.summary} severity="info" text={emptyText || t('empty')} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {getVisibleRows(rows).map((row, index) => {
                const isItemSelected = isSelected(row['id']);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    // TODO: fix hover is object insted of boolean
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={shortid.generate()}
                    selected={isItemSelected}
                    style={{ height: 53 }}
                  >
                    {allowRowSelection && (
                      <TableCell padding="checkbox">
                        {ableSelectRow(row) ? (
                          <Checkbox
                            onClick={() => onCheckElement(row)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    )}
                    {cells.map(cell => {
                      return (
                        <TableCell
                          key={shortid.generate()}
                          align={cell.numeric ? 'right' : cell.formatAsColumn ? 'center' : 'left'}
                          width={cell.formatAsColumn ? '20%' : ''}
                          style={cell.style && cell.style}
                          className={`${!cell.action &&  !cell.additionalProperty && classes.ellipsis} ${
                            Boolean(cell.action && !cell.formatAsColumn) && classes.actionCell
                          }`}
                        >
                          {cell.additionalProperty &&
                            cell.additionalProperty.beforeParent &&
                            cell.additionalProperty.value(row)}

                          {isSkeletonLoading(row[cell.id]) ? (
                            <Skeleton />
                          ) : row[cell.id]?.length > 20 ? (
                            <Tooltip classes={{ tooltip: classes.tooltip }} title={row[cell.id]} placement="top">
                              <span>{row[cell.id]}</span>
                            </Tooltip>
                          ) : cell.rating ? (
                            <Rating value={row[cell.id]} precision={0.5} readOnly />
                          ) : typeof row[cell.id] === 'boolean' ? (
                            //TODO: Smell... remove warning property and refactor with custom control property
                            cell.warning ? (
                              row[cell.id] && (
                                <Tooltip title={cell.warning} placement="top">
                                  <WarningRounded style={{ color: color.toast.warn, fontSize: 30 }} />
                                </Tooltip>
                              )
                            ) : (
                              <BooleanIcon
                                condition={row[cell.id]}
                                trueText={cell.tooltip && cell.tooltip.trueText}
                                falseText={cell.tooltip && cell.tooltip.falseText}
                                tooltipClasses={{ tooltip: classes.tooltip }}
                                showOnlyCheck={cell.showOnlyCheck}
                              />
                            )
                          ) : cell.additionalProperty ? (
                            <div className={classes.verticalAlign}>{row[cell.id]}</div>
                          ) : cell.action ? (
                            renderAction(row[cell.id], row)
                          ) : (
                            row[cell.id]
                          )}

                          {cell.additionalProperty &&
                            cell.additionalProperty.afterParent &&
                            cell.additionalProperty.value(row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {summaryCells && cells && (
                <ViewTableSummary
                  classes={classes}
                  allowRowSelection={allowRowSelection}
                  headCells={cells}
                  summaryCells={summaryCells}
                  actions={actions}
                />
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div className={classes.footerWrapper}>
        <div className={classes.wrapper}>
          {isAnySelected && (
            <Toolbar disableGutters className={`${classes.root} ${classes.selected} ${classes.highlight}`}>
              <Typography color="inherit" variant="subtitle1" component="div">
                {checkedElements?.length} {t('common:actions.selected')}
              </Typography>
            </Toolbar>
          )}
          {allowPaging && rows.length > 0 && (
            <TablePagination
              component="div"
              style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
              count={totalRowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              onPageChange={changePage}
              onRowsPerPageChange={changeRows}
              ActionsComponent={ViewTableFooter}
              labelRowsPerPage={t('viewTable.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} ${t('viewTable.of')} ${count}`
              }
            />
          )}
        </div>
      </div>
      {/* </Paper> */}
      {editModal && editModal}
    </div>
  );
}

export const ViewTable = React.memo(_viewTable);

_viewTable.propTypes = {
  mainKey: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string,
    severity: PropTypes.string
  }),
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  serverSidePaging: PropTypes.bool,
  onFetchData: PropTypes.func,
  totalRows: PropTypes.number,
  showId: PropTypes.bool,
  allowRowSelection: PropTypes.bool,
  elevation: PropTypes.bool,
  allowPaging: PropTypes.bool,
  defaultOrderBy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  toolbar: PropTypes.object,
  spaced: PropTypes.bool,
  checkRowWhen: PropTypes.func,
  emptyText: PropTypes.string,
  disableOrderBy: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      properties: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
      cb: PropTypes.func,
      icon: PropTypes.node
    }).isRequired
  ),
  allowRowFilter: PropTypes.bool,
  summaryCells: PropTypes.arrayOf(PropTypes.object),
  booleanIcons: PropTypes.arrayOf(PropTypes.element),
  editModal: PropTypes.object
};

_viewTable.defaultProps = {
  serverSidePaging: false,
  totalRows: -1,
  allowRowSelection: true,
  allowPaging: true,
  elevation: false,
  disableOrderBy: false,
  spaced: false,
  allowRowFilter: true
};
