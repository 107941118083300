
export const color = {
  branding: {
    blue: '#4d5df1',
    red: 'rgb(255 87 87)',
    green: 'rgb(52 191 73)'
  },
  basic: {
    brightest: 'rgba(219, 222, 252, 0.55)',
    brighter: '#B9C6DA',
    bright: '#c2c5ca',
    normal: '#8A9AAF',
    semidark: '#4A566D',
    dark: '#14142A',
    darker: '#000000'
  },
  primary: {
    dark: '#333333',
    main: '#24bffa',
    light: '#b8eafd',
    light1: '#def5fe',
    lighter: '#e9f8fe',
    lightest: '#f7fcff'
  },
  secondary: {
    dark: '#c2c5ca',
    light: '#ffffff',
    main: '#f5f8fd'
  },
  action: {
    error: '#f44336',
    activeButton: 'rgba(77, 93, 241, 0.7)',
    delete: {
      main: '#ff4c4c',
      light: '#eed5e3'
    },
  },
  table: {
    header: 'rgb(243, 242, 247)',
    summary: 'rgb(243, 242, 247)'
  },
  buttons: {
    disabled: {
      background: '#d8d9e4',
      text: '#838383'
    }
  },
  status: {
    green: '#2ACC92',
    yellow: '#FFB935',
    red: '#EB3030',
    navyBlue: '#232977',
    blue: '#4d5df1',
    purple: '#5515A7'
  },
  toast: {
    error: 'rgb(254 112 98)',
    warn: 'F9A825'
  },
  boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
  rating: '#ffb400',
};
